{
  "CONNECTION_CLOSED": "The server connection dropped temporarily.",
  "DISCONNECTED": "Dabble is not connected to the server.",
  "about": "About",
  "actions_none": "No actions",
  "add_new_document": "Add New $t({{type}})",
  "novel_prologue": "Prologue",
  "add_document": "Add $t({{type}})",
  "author_unknown": "Unknown Author",
  "back_home": "Home",
  "back_project": "Back to Project",
  "blog": "Blog",
  "cancel": "Cancel",
  "clear_dictionary": "Remove All",
  "confirm": "Confirm",
  "context_menu_paste_permission_title": "",
  "context_menu_paste_permission_denied": "You have previously blocked Dabble from pasting.\n\nFollow the instructions at:\n\nhttp://help.dabblewriter.com/en/articles/3951648-allow-dabble-to-paste-on-right-click\n\nThen try again.",
  "context_menu_paste_permission_prompt": "Permission to paste from Dabble’s right-click menu is required.\n\nClick “Allow” when asked if Dabble can paste for you.",
  "context_menu_paste_permission_unsupported": "This browser does not support pasting from Dabble’s custom right-click menu.\n\nPlease use the keyboard shortcut:\n\n{{shortcut}}",
  "context_menu_paste_book_exception_title": "Pasting a Book?",
  "context_menu_paste_book_exception_text": "It looks like you are trying to paste a book into Dabble.  Please use the keyboard shortcut instead.\n\nkeyboard shortcut: {{shortcut}}",
  "counts_pages": "Page",
  "counts_pages_other": "Pages",
  "counts_pages_tooltip": "Estimate based on 250 words per page",
  "counts_selection": "Selected Word",
  "counts_selection_other": "Selected Words",
  "counts_selection_tooltip": "Selected word count",
  "counts_today": "Today",
  "counts_today_tooltip": "Total word count change for this document today",
  "counts_words": "Word",
  "counts_words_other": "Words",
  "counts_words_tooltip": "Word count for this document",
  "dabble_community": "Story Craft Café",
  "delete": "Delete",
  "delete_item": "Delete",
  "delete_permanently": "Delete Permanently",
  "delete_permanently_confirm": "Are you sure you want to permanently delete “{{-title}}”?",
  "delete_permanently_confirm_children": "Are you sure you want to permanently delete “{{-title}}” and all of its children?",
  "delete_confirm": "Are you sure you want to delete “{{-title}}”?",
  "dialog_cancel": "Cancel",
  "dialog_no": "No",
  "dialog_ok": "OK",
  "dialog_yes": "Yes",
  "document_copy": "Copy $t({{type}})",
  "document_paste": "Paste",
  "document_paste_doc": "Paste $t({{type}})",
  "edit": "Edit",
  "edit_title": "Rename $t({{type}})",
  "convert_from_prologue": "Convert to Chapter",
  "empty_trash": "Empty Trash",
  "empty_trash_confirm": "Are you sure you want to permanently delete everything in the trash?",
  "error": "Error",
  "feature_coauthoring": "Co-authoring",
  "feature_comments": "Comments",
  "feature_dark_mode": "Dark Mode",
  "feature_focus": "Focus",
  "feature_goals": "Goals & Stats",
  "feature_grammar": "Grammar",
  "feature_images": "Images",
  "feature_not_available": "{{feature}} Unavailable",
  "feature_plotting": "Plotting",
  "feature_spell_check": "Spell Check",
  "feature_stickies": "Sticky Notes",
  "feature_story_notes": "Story Notes",
  "feature_support_email": "Email Support",
  "feature_support_center": "Support Center",
  "feature_support_chat": "Chat Support",
  "feature_upgrade_now": "Upgrade Now",
  "feature_upgrade": "The {{feature}} feature is not available in this plan. Please upgrade to use.",
  "feature_versioning": "Versioning",
  "file": "File",
  "file_untitled": "Untitled File",
  "filter_placeholder": "Filter project files…",
  "filter_tooltip": "Filter your files by the text contained in them.",
  "focus_disable": "Disable focus mode",
  "focus_enable": "Enable focus mode",
  "focus_enter": "Enter focus mode",
  "focus_exit": "Exit focus mode",
  "folder": "Folder",
  "folder_untitled": "Untitled Folder",
  "fullscreen_enter": "Enter fullscreen",
  "fullscreen_exit": "Exit fullscreen",
  "go_back": "Go Back <highlight>{{shortcut}}</highlight>",
  "go_forward": "Go Forward <highlight>{{shortcut}}</highlight>",
  "loading": "Loading",
  "nav_item_actions": "$t({{type}}) Actions…",
  "new_project": "Create Blank $t({{type}})",
  "new_document": "New $t({{type}})",
  "not_found_doc": "Document not Found",
  "not_found_project": "Project not Found",
  "not_found_project_data": "Project Missing Data",
  "insert_document": "Insert $t({{type}})",
  "cannot_insert_document": "Cannot insert $t({{type}}) here.",
  "new_file": "New File",
  "new_items": "Create…",
  "learn_more": "Learn more…",
  "open": "Open “{{-title}}”",
  "paste_error_incompatible_title": "Paste Error",
  "paste_error_incompatible_body": "$t({{type}}) is not compatible with $t({{parentType}}).",
  "privacy_policy": "Privacy Policy",
  "project": "Project",
  "projects": "Projects",
  "project_by": "By",
  "project_copy": "Copy",
  "project_settings": "Project Settings",
  "project_rename_header": "Project Name",
  "project_rename_body": "Set the name of your project",
  "project_actions": "Project Actions…",
  "project_duplicate": "Duplicate Project",
  "project_error_title": "We couldn’t save that",
  "project_error_body": "There was an error saving the last change you made. Reload Dabble and try again, or contact support.\n\nError:\n{{- error}}",
  "project_no_permissions": "You no longer have permission to modify this project, contact support",
  "project_shared_template": "Shared template",
  "project_sync_off": "This project is not syncing to the server, contact support if you believe this is an error",
  "project_rename": "Rename Project",
  "project_restore": "Restore project",
  "project_select": "Open project",
  "project_title_copy": "{{title}} Copy",
  "project_untitled": "Untitled Project",
  "recent_projects": "Recent projects",
  "request_feature": "Request a Feature",
  "restore_from_trash": "Restore from Trash",
  "save": "Save",
  "saved": "Saved",
  "saving": "Saving…",
  "send_to_trash": "Send to Trash",
  "search_icon_tooltip": "Find &amp; Replace",
  "set_icon": "Set Icon",
  "set_icon_overlay": "Set Icon Overlay",
  "show_more_templates": "Show templates",
  "hide_templates": "Hide templates",
  "start_new_project": "Start a new project from a template",
  "still_saving": "Your project is currently saving, continue in a moment",
  "success": "Success",
  "suggestions": "Suggestions",
  "support_chat": "Chat with support",
  "support_email": "Email support",
  "support_visit_help": "Visit the Help Center",
  "tagline": "Craft your story",
  "terms_of_service": "Terms of Service",
  "text_copy": "Copy",
  "text_cut": "Cut",
  "text_delete": "Delete",
  "text_dictionary": "Dictionary Maintenance",
  "text_paste": "Paste",
  "text_redo": "Redo",
  "text_selectall": "Select All",
  "text_thesaurus": "Synonyms",
  "text_undo": "Undo",
  "today": "Today",
  "toggle_focus": "Toggle Focus Mode",
  "toggle_fullscreen": "Toggle Fullscreen",
  "toggle_navigation": "Toggle Navigation",
  "toggle_sidebar": "Toggle Sidebar",
  "trash": "Trash",
  "trash_confirm": "Are you sure you want to trash “{{-title}}”?",
  "trashed_projects": "Trashed Projects",
  "ui_toggle_tooltip": "Toggle Features",
  "update_installed": "An <highlight>update</highlight> has been installed!<br>Relaunch now.",
  "update_installing": "Downloading\nupdate…",
  "update_installing_percent": "Downloading\nupdate…\n{{percent}}%",
  "copied_to_clipboard": "Copied to clipboard",
  "transfer_ownership": "Transfer Ownership",
  "transfer_ownership_project": "Which account (uid) do you want to transfer ownership for project {{project_title}}?",
  "transfer_complete": "The transfer is complete!",
  "share_ownership": "Share Project",
  "share_ownership_project": "Which account (uid) do you want to add as a co-author for project {{project_title}}?",
  "share_complete": "Project successfully shared!",
  "updated_project_formatting": "Successfully updated project formatting",
  "alert_header_error_occured": "An error occurred",
  "alert_error_occured_message": "You may want to reload/restart Dabble and contact support if it keeps happening.\n\nError:\n{{error_message}}",
  "alert_header_old_desktop": "Old Desktop Client",
  "alert_old_desktop": "Your desktop client needs to be manually updated. Please go to https://www.dabblewriter.com/downloads in your browser to download the latest version (1.6.0 or higher).",
  "alert_header_database_closed": "Database Closed",
  "alert_database_closed": "Your local database has closed. Dabble will reload to open it again. If this keeps occuring, please conact support.",
  "alert_projet_not_fully_loaded": "Error, project data not fully loaded",
  "alert_header_trial_error": "Trial Error",
  "alert_trial_error": "There was an error starting your trial. Please, contact support. We really want to help.",
  "alert_header_email_sent": "Email Sent",
  "alert_email_sent": "An email was sent to {{email}}",
  "alert_header_error": "Error",
  "alert_error": "",
  "alert_header_succesfully_upgraded": "User Successfully Upgraded",
  "alert_succesfully_upgraded": "You upgraded the user to free!",
  "alert_header_account_merged": "Account Successfully Merged",
  "alert_account_merged": "You merged the user accounts",
  "alert_header_user_deleted": "User Successfully Deleted",
  "alert_user_deleted": "You deleted the user account",
  "confirm_header_delete_account": "Delete This Account",
  "confirm_delete_account": "Would you like to delete this account now?",
  "confirm_delete_user": "Are you sure you want to delete {{user_name}}???",
  "confirm_header_replace": "Sure?",
  "confirm_replace": "Replace All cannot be undone. Are you sure you want to do this?",
  "confirm_header_unsynced_data": "STOP! Unsynced Data",
  "confirm_unsynced_data": "Are you sure you want to sign out?\n\nYou have unsynced data in some of your projects which will be lost if you sign out.",
  "confirm_header_resync": "Re-sync?",
  "confirm_resync": "Resync after you made changes on NaNoWriMo.org?",
  "confirm_header_nanowrimo_reauth": "NaNoWriMo Re-Auth",
  "confirm_nanowrimo_reauth": "Your NaNoWriMo authentication has expired. Would you like to re-authenticate?",
  "confirm_give_user_admin": "Are you sure you want to give {{user_name}} access Free4Eva’?",
  "confirm_make_user_admin": "Are you sure you want to make {{user_name}} an admin with full admin priviledges?",
  "prompt_header_merge": "Merge Into?",
  "prompt_merge": "What account do you want to merge THIS into?\n\nTHIS ONE WILL BE DELETED",
  "inform_header_refreshed": "Refreshed",
  "inform_refreshed": "Data has been refreshed.",
  "inform_header_not_refreshed": "Not Refreshed",
  "inform_not_refreshed": "Data could not be refreshed.",
  "alert_header_user_upgraded": "User Successfully Upgraded",
  "alert_user_upgraded": "You made this user an admin",
  "alert_header_dabble_demo": "Dabble Demo",
  "alert_dabble_demo": "This is a demo of the Dabble application only!\n\n'No support is provided. Cloud sync is not enabled. Offline support is not enabled. 'We make no guarentee about data loss. And it is only updated occassionally.\n\n'Use at your own risk.\n\nVisit https://app.dabblewriter.com/ to sign up for a Dabble account.\n\nEnjoy!!",
  "alert_header_updated": "Updated",
  "alert_updated": "It has been updated. If you need to change it, go to your Goal Settings and update \"Exclude word count\".",
  "alert_header_sounds_good": "Sounds Good",
  "alert_sounds_good": "If you change your mind, you can set it by going to your Goal Settings and updating \"Exclude word count\".",
  "alert_header_dictionary_error": "Error Adding To Dictionary",
  "alert_dictionary_error": "",
  "alert_header_not_available": "Not Available",
  "alert_not_available": "Dark mode is only available in Standard or Premium. Upgrade to use it!",
  "alert_header_backfill_failed": "Backfill Failed",
  "alert_backfill_failed_1": "We could not find a snapshot for version {{version}}",
  "alert_backfill_failed_2": "We could only backfill to version {{version}}",
  "alert_header_session_timeout": "Session Timeout",
  "alert_session_timeout": "Your session has expired. We will reload the page for an optimal experience.",
  "home_title": "Title",
  "home_subtitle": "Subtitle",
  "home_author": "Author",
  "home_trash_empty": "You have no projects in the trash.",
  "home_templates_empty": "You have no templates. You can convert a project into a template from its action menu!",
  "template": "Template",
  "project_create": "Convert to Template",
  "project_archive": "Archive",
  "project_unarchive": "Unarchive",
  "template_edit": "Edit Template",
  "archived_projects": "Archived Projects",
  "template_share": "Share Template",
  "template_shared_link": "The template has been made public and a share link copied to your clipboard.",
  "template_not_sharable": "The project shared is not a valid template.",
  "template_shared": "<strong>{{templater}}</strong> has shared <strong>{{title}}</strong> with you.",
  "template_accept": "You are logged in with <strong>{{email}}</strong>. Do you wish to accept this template shared with you?",
  "template_signin_first_message": "Please sign in to accept this template invite.",
  "template_view": "View Template",
  "template_actions": "Template Actions…",
  "template_new": "Create $t({{name}}) $t({{type}})",
  "template_rename": "Rename Template",
  "template_settings": "Template Settings",
  "template_count": "Number of users using this template",
  "template_confirm": "Are you sure you want to make this project a template?",
  "dabble_shortcuts": "Dabble Shortcuts",
  "nav_delete": "Delete Navigation item selected",
  "doc_heading_1": "Format to a Heading 1",
  "doc_heading_2": "Format to a Heading 2",
  "doc_heading_3": "Format to a Heading 3",
  "doc_heading_4": "Format to a Heading 4",
  "doc_heading_5": "Format to a Heading 5",
  "doc_heading_6": "Format to a Heading 6",
  "doc_bold": "Format bold",
  "doc_italic": "Format italic",
  "doc_copy": "Copy selection",
  "doc_cut": "Cut selection",
  "doc_paste": "Paste selection",
  "doc_undo": "Undo last change",
  "doc_redo": "Redo last change",
  "doc_find": "Open Find tool",
  "doc_replace": "Open Find & Replace tool",
  "doc_new_scene": "Create New Scene after cursor",
  "doc_new_chapter": "Create New Chapter after cursor",
  "doc_current_mac": "Goto Current Selection (mac only)",
  "doc_next_mac": "Goto Next Selection (mac only)",
  "navigation_pane": "Navigation Pane",
  "formatting": "Formatting",
  "dabble_document": "Dabble Document",
  "find": "Find",
  "replace": "Replace",
  "find_replace": "Find & Replace",
  "find_in": "Find in",
  "find_in_project": "Find in Project",
  "match_word": "Match Whole Word",
  "match_case": "Match Case",
  "use_regex": "Use Regular Expressions",
  "replace_once": "one",
  "replace_all": "all",
  "shortcuts": "Shortcuts",
  "add_new": "Add New",
  "close": "Close",
  "templates": "Templates",
  "templates_add_from": "Add From Template",

  "speechify_prompt_title": "Speechify Extension Installed",
  "speechify_prompt_message": "We notice that you have Speechify installed. This extension has been known to cause Dabble some issues, most notably repetitive words while typing or being added randomly. If you experience these problems please try reinstalling the extension, and if the problem persists turn the extension off. We are evaluating other solutions."
}
