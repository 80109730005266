<script lang="ts">
  import { Editable } from '@dabble/data/editables';
  import { t } from '@dabble/data/intl';
  import { projectMetaStore, projectStore } from '@dabble/data/project-data';
  import { Doc } from '@dabble/data/types';
  import { editingModeStore, inform } from '@dabble/data/ui';
  import { EMPTY_ARRAY, EMPTY_OBJECT } from '@dabble/data/util';
  import FullPageImage from '@dabble/plugins/novel/components/FullPageImage.svelte';
  import SceneContents from '@dabble/plugins/novel/components/SceneContents.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Page from '@dabble/toolkit/Page.svelte';
  import { tick } from 'svelte';

  export let doc: Doc;
  export let last = true;
  let font;
  let headerEditable: Editable;
  let emptyTitle = false;
  let docJustChanged = false;

  $: font = $projectMetaStore.settings?.font || EMPTY_OBJECT;
  $: scenes = $projectStore.childrenLookup[doc.id] || EMPTY_ARRAY;
  $: lastScene = last && scenes[scenes.length - 1];
  $: sceneClass =
    'scene-view fill' +
    ` paragraph-spacing-${font.sceneSpacing || 'double'}` +
    ` paragraph-style-${font.sceneStyle || 'book'}`;
  $: if (headerEditable && !docJustChanged && emptyTitle && doc.unnumbered) {
    projectStore.updateDoc(doc.id, { unnumbered: undefined });
    headerEditable.editor.render();
  }
  $: if (doc) {
    docJustChanged = true;
    tick().then(() => (docJustChanged = false));
  }

  async function addScene() {
    await projectStore.createDoc({ type: 'novel_scene' }, doc.id);
    await tick();
    inform('success', $t('novel_scene_added'), $t('novel_scene_added_body'));
  }
</script>

<Page {doc} class="chapter-view {doc.unnumbered ? 'unnumbered' : ''}">
  <EditableContent
    {doc}
    field="title"
    header="h1"
    bind:editable={headerEditable}
    readonly={$editingModeStore === 1}
    prefix={!doc.unnumbered && doc.type !== 'novel_prologue'}
    bind:isEmpty={emptyTitle}
  />
  {#each scenes as child}
    {#if child.type === 'novel_scene'}
      <SceneContents className={sceneClass + (lastScene === child ? ' fill-page' : '')} doc={child} />
    {:else if child.type === 'novel_image'}
      <div class="image-container {sceneClass}">
        <FullPageImage doc={child} />
      </div>
    {/if}
  {/each}
  {#if !scenes.length}
    <div class="no-scenes" on:click={addScene} on:keydown={addScene} role="button" tabindex="0">
      <div>No scenes in this chapter</div>
      <div>Click here to add a scene to write</div>
    </div>
  {/if}
</Page>

<style>
  :global(.chapter-view .page > .editable-content[data-field='title'] h1) {
    padding-bottom: 2em;
  }
  :global(.scene-view .typewriter-editor) {
    padding-bottom: 3em;
    position: relative;
  }
  :global(.scene-view + .scene-view .typewriter-editor) {
    padding-top: 3em;
  }
  :global(.scene-view + .scene-view .typewriter-editor::before) {
    position: relative;
    top: -3em;
    display: block;
    text-align: center;
    content: ' ***';
    letter-spacing: 4em;
    color: var(--text-color-light);
    line-height: 0;
    white-space: pre;
  }
  .no-scenes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--background-lightened);
    color: var(--text-color-lighterer);
    text-transform: uppercase;
    text-align: center;
    height: 200px;
    cursor: pointer;
  }
  .no-scenes:hover {
    border: 1px dashed var(--dabble-blue);
  }
  .image-container {
    margin-bottom: 70px;
  }
  .image-container::before {
    margin-top: 50px;
    position: relative;
    top: -3em;
    display: block;
    text-align: center;
    content: ' ***';
    letter-spacing: 4em;
    color: var(--text-color-light);
    line-height: 0;
    white-space: pre;
  }

  @media (max-width: 600px) {
    :global(.scene-view + .scene-view .typewriter-editor::before) {
      letter-spacing: 3em;
    }
  }
  @media (max-width: 400px) {
    :global(.scene-view + .scene-view .typewriter-editor::before) {
      letter-spacing: 2em;
    }
  }
</style>
