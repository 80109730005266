import { projectStore } from '@dabble/data/project-data';
import ProjectPatch from '@dabble/data/project-patch';
import { ProjectStore } from '@dabble/data/stores/project';
import { Doc, ProjectDocs } from '@dabble/data/types';
import { JSONPatch, JSONPatchOp } from '@typewriter/json-patch';

export type Column = string[];
export type Grid = Column[];
type ParentIndex = [Doc?, number?];
type PlotSet = Set<string>;

export interface PlotDoc extends Doc {
  grid: Grid;
}

interface ErrorChecker {
  plotsWithErrors: Set<string>;
  docExists: (parent: Doc, doc: Doc) => boolean;
}

interface State {
  projectPatch: ProjectPatch;
  projectBefore: ProjectStore;
  projectAfter: ProjectStore;
  handledPlots: PlotSet;
  errorChecker: ErrorChecker;
  deletedDocs: { [docId: string]: true };
  unchangedGrids: { [docId: string]: true };
  ops: JSONPatchOp[]; // The ops as they were before any changes were added
}

const gridExp = /^\/docs\/([^/]+)\/grid/;
const docExp = /^\/docs\/([^/]+)$/;
const childrenExp = /^\/docs\/([^/]+)\/children\/([^/]+)$/;
const linkExp = /^\/links\//;
const empty: ParentIndex = [];
const bookTypes = new Set(['novel_book', 'novel_part', 'novel_chapter', 'novel_scene']);
const TYPE_BOOK_SCENES = 'novel_book_scenes';
const HANDLED_OPS = new Set(['add', 'move', 'remove']);

/*
Handle:
### Adding/removing from the grid itself and adding/removing plot point links to scenes ###
- Creating a new plot grid for an existing book
- Restoring a plot grid from the trash
- Deleting a plot grid or moving it to the trash
- Moving a scene within the book
- Moving a chapter/part within the book
- Adding a plot point to a plot line
- Removing a plot point from a plot line
- Adding a plot line to a plot grid
- Removing a plot line from a plot grid
- Attaching a plot grid to a book
- Detaching a plot grid from a book
- Moving a scene out of the book or deleting it
- Moving a scene into the book or creating it
- Moving a chapter/part out of the book or deleting it
- Moving a chapter/part into the book or deleting it
- Don't update a grid when an update was included in the original patch
- Update other grids when an update was included for one grid
*/

// TODO: grid updater needs to be looked into!!!
/* projectStore.onPatch(projectPatch => {
  if (projectPatch.project.type !== 'novel') return;
  if (!projectPatch.patch.ops.some(op => HANDLED_OPS.has(op.op))) return;

  const state: State = {
    projectPatch: new ProjectPatch(projectPatch.project, projectPatch.settings, null),
    projectBefore: createProjectStore(null, settingsStore), // project state before the current change
    projectAfter: createProjectStore(null, settingsStore), // project state after the current change, before any adjustments
    handledPlots: findHandledPlots(projectPatch.patch.ops),
    errorChecker: getErrorChecker(),
    deletedDocs: {},
    unchangedGrids: {},
    ops: projectPatch.patch.ops.slice(),
  };

  // const projectAfter = projectPatch.patch.apply(projectPatch.project);

  // Shortcut, check if any changes actually need to be applied to the grid
  // Object.values(projectAfter.docs).forEach(doc => {
  //   if (doc.type === 'novel_plot') {
  //     const hasLink = !!Object.keys(projectAfter.links).find(key => key.startsWith(`${doc.id}:plot:`));
  //     const grid = updateGrid(projectAfter, doc.grid, getGridFromPlot(projectAfter, doc.id), hasLink, true);
  //     if (doc.grid === grid) {
  //       state.unchangedGrids[doc.id] = true;
  //     }
  //   }
  // });
  state.projectBefore.set(projectStore.get());
  state.projectAfter.set(projectStore.get());
  const newOps: JSONPatchOp[] = [];

  state.ops.forEach(op => {
    newOps.push(op);
    state.projectAfter.updateData(new JSONPatch([op]).apply(state.projectAfter.get().project));
    state.projectPatch.patch.ops = [op];
    checkOp(state, op);
    state.projectPatch.patch.ops.shift();
    if (state.projectPatch.patch.ops.length) {
      newOps.push(...state.projectPatch.patch.ops);
      // Update projectAfter to the latest change
      state.projectAfter.updateData(state.projectPatch.patch.apply(state.projectAfter.get().project));
      state.projectPatch.patch.ops.length = 0;
    }
    state.projectPatch.project = state.projectAfter.get().project;
    state.projectBefore.set(state.projectAfter.get());
  });

  // Check that this was done successfully. If not, rollback entire change and alert the user
  projectPatch.patch.ops = newOps;
  const newProjectAfter = state.projectAfter.get().project;
  Object.values(newProjectAfter.docs).every(doc => {
    if (doc.type === 'novel_plot') {
      const hasLink = !!Object.keys(newProjectAfter.links).find(key => key.startsWith(`${doc.id}:plot:`));
      if (!doc.grid) {
        // Fix plots without any grid
        doc.grid = getGridFromPlot(newProjectAfter, doc.id);
        projectPatch.updateDoc(doc.id, { grid: doc.grid });
      } else {
        const grid = updateGrid(newProjectAfter, doc.grid, getGridFromPlot(newProjectAfter, doc.id), hasLink, true);
        if (doc.grid !== grid) {
          if (localStorage.log === '*') {
            console.log('original ops:', state.ops);
            console.log('updated ops:', projectPatch.patch.ops.slice());
            console.log('original grid:', projectStore.get().docs[doc.id]?.grid);
            console.log('udpated grid:', doc.grid);
            console.log('corrected grid:', grid);
          }
          if (!delegate) {
            projectPatch.patch.ops.length = 0;
            alert($t('novel_plot_incorrect_title'), $t('novel_plot_incorrect_body'));
            return false;
          }
        }
      }
    }
    return true;
  });
}); */

function checkOp(state: State, op: JSONPatchOp) {
  if (op.op === 'remove') {
    if (childrenExp.test(op.path)) {
      removeChild(state, op.path);
    } else if (linkExp.test(op.path)) {
      removeLink(state, op.path);
    } else if (docExp.test(op.path)) {
      state.deletedDocs[op.path.match(docExp)[1]] = true;
    }
  } else if (op.op === 'add') {
    if (docExp.test(op.path)) {
      createDoc(state, op.value);
    } else if (childrenExp.test(op.path)) {
      addChild(state, op.path, op.value);
    } else if (linkExp.test(op.path)) {
      addLink(state, op.path);
    }
  } else if (op.op === 'move') {
    if (childrenExp.test(op.from)) {
      moveChild(state, op);
    }
  } else if (op.op === 'replace') {
    // No cases to handle here
  } else if (op.op === 'copy') {
    // No cases to handle here
  }
}

function moveChild(state: State, op: JSONPatchOp) {
  const [parent, index] = getParentAndIndex(state, op.from);
  const [toParent, toIndex] = getParentAndIndex(state, op.path);
  const doc = state.projectBefore.getDoc(parent.children[index]);
  const fromBook = state.projectBefore.closest(parent, 'novel_book');
  const toBook = state.projectBefore.closest(toParent, 'novel_book');
  if (!state.errorChecker.docExists(parent, doc)) return;

  if (doc.type === 'novel_plot_line' && parent.type === 'novel_plot' && parent === toParent) {
    moveColumn(state, doc, index, toIndex);
  } else if (bookTypes.has(doc.type) && fromBook && fromBook === toBook) {
    moveBookPart(state, doc, parent, index, toParent, toIndex);
  } else {
    const docId = removeChild(state, op.from);
    addChild(state, op.path, docId);
  }
}

function removeChild(state: State, path: string) {
  const [parent, index] = getParentAndIndex(state, path);
  const doc = state.projectBefore.getDoc(parent.children[index]);
  if (!state.errorChecker.docExists(parent, doc)) return;

  if (doc.type === 'novel_scene' || doc.type === 'novel_plot_point') {
    removeDoc(state, doc, parent, index);
  } else if (doc.type === 'novel_plot_line' && parent.type === 'novel_plot') {
    if (!state.deletedDocs[parent.id]) {
      removeColumn(state, doc, parent as PlotDoc);
    }
  } else if (state.projectBefore.closest(parent, 'novel_book')) {
    removeBookPart(state, doc, parent, index);
  }
  return doc.id;
}

function addChild(state: State, path: string, docId: string) {
  const [parent, index] = getParentAndIndex(state, path);
  const doc = getTargetDoc(state, docId);
  if (!state.errorChecker.docExists(parent, doc)) return;

  if (doc.type === 'novel_scene' || doc.type === 'novel_plot_point') {
    addDoc(state, doc, parent, index);
  } else if (doc.type === 'novel_plot_line' && parent.type === 'novel_plot') {
    if (!state.handledPlots.has(parent.id)) {
      addColumn(state, doc, parent as PlotDoc, index);
    }
  } else if (state.projectBefore.closest(parent, 'novel_book')) {
    // Using project.closest will ensure we skip the chapter created within a new part
    addBookPart(state, doc, parent, index);
  }
}

function createDoc(state: State, doc: Doc) {
  // Whenever a new book is created, automatically create a plot grid for it
  if (doc.type === 'novel_book') {
    createBook(state, doc);
  } else if (doc.type === 'novel_plot') {
    createPlot(state, doc);
  }
}

function addLink(state: State, path: string) {
  const [plotId, rel, bookId] = path.split('/').pop().split(':');
  if (rel !== 'plot') return;
  const plot = state.projectBefore.getDoc(plotId) as PlotDoc;
  const book = getTargetDoc(state, bookId);
  if (!plot || plot.type !== 'novel_plot' || !book || book.type !== 'novel_book' || state.handledPlots.has(plot.id)) {
    return;
  }

  addBookScenes(state, plot, book);
}

function removeLink(state: State, path: string) {
  const [plotId, rel, bookId] = path.split('/').pop().split(':');
  if (rel !== 'plot') return;
  const plot = getTargetDoc(state, plotId) as PlotDoc;
  if (!plot || state.deletedDocs[plot.id]) return;
  const book = getTargetDoc(state, bookId);
  if (!plot || plot.type !== 'novel_plot' || !book || book.type !== 'novel_book') return;

  const bookScenes = state.projectBefore.getDoc(getBookScenesId(book));
  removeColumn(state, bookScenes, plot, true);
}

function getTargetDoc(state: State, docId: string, forceAfter?: boolean) {
  return forceAfter ? state.projectAfter.getDoc(docId) : state.projectBefore.getDoc(docId);
}

function getParentAndIndex(state: State, path: string): ParentIndex {
  const match = path.match(childrenExp);
  if (!match) return empty;
  const parent = getTargetDoc(state, match[1]);
  const index = parent && (match[2] === '-' ? parent.children.length : parseInt(match[2]));
  return [parent, index];
}

function findHandledPlots(ops: JSONPatchOp[]) {
  const handledGrids: PlotSet = new Set();
  ops.forEach(op => {
    const match = op.path.match(gridExp);
    if (match) {
      handledGrids.add(match[1]);
    }
  });
  return handledGrids;
}

function getErrorChecker(): ErrorChecker {
  const plotsWithErrors = new Set<string>();

  function docExists(parent: Doc, doc: Doc) {
    if (!doc) {
      const plot = projectStore.closest(parent, 'novel_plot');
      if (plot) plotsWithErrors.add(plot.id);
      return false;
    }
    return true;
  }

  return {
    plotsWithErrors,
    docExists,
  };
}

function createBook(state: State, book: Doc) {
  const scenes = state.ops
    .filter(op => op.op === 'add' && op.value.type === 'novel_scene')
    .map(scene => scene.value.id);
  if (!state.projectBefore.getDoc('plots') || !scenes.length || scenes.length > 1) return;

  const bookId = book.id;
  state.projectPatch.createDoc({ type: 'novel_plot' }, 'plots');
  const plot: PlotDoc = state.projectPatch.patch.ops.find(
    op => op.op === 'add' && op.value.type === 'novel_plot'
  ).value;
  const line: Doc = state.projectPatch.patch.ops.find(
    op => op.op === 'add' && op.value.type === 'novel_plot_line'
  ).value;

  state.projectPatch.linkDocs(plot.id, 'plot', bookId);
  state.projectPatch.linkDocs(line.children[0], 'plot', scenes[0]);

  const gridLines = [...line.children];

  while (gridLines.length < scenes.length) {
    gridLines.push(null);
  }

  plot.grid = [
    [bookId, ...scenes],
    [line.id, ...gridLines],
  ];
}

function createPlot(state: State, plot: Doc) {
  // Whenever a new plot grid is created, create its grid property
  plot.grid = plot.children.map(lineId => {
    const line = state.projectBefore.getDoc(lineId);
    return [line.id].concat(line.children);
  });
}

function removeBookPart(state: State, doc: Doc, parent: Doc, index: number) {
  const sceneIds = state.projectBefore.getDocsOfType(doc, 'novel_scene');
  [parent, index] = getBookPartIndex(state, parent, index);

  getPlots(state).forEach(plot => {
    if (state.handledPlots.has(plot.id)) return;
    const children = state.projectBefore.getChildren(plot.id);
    const x = children && children.indexOf(parent);
    if (!children || x === -1) return;
    const id = parent.children[index];
    let indexOffset = plot.grid[x].indexOf(id);
    const grid = plot.grid.map(col => col.slice()); // clone grid
    sceneIds.forEach((sceneId, i) => {
      const y = indexOffset + i;
      const removedRow = removeDocFromGrid(state.projectPatch, plot, grid, x, y, sceneId);
      if (removedRow) {
        indexOffset--;
        grid.forEach(col => col.splice(y, 1));
      }
    });
  });
}

function addBookPart(state: State, doc: Doc, parent: Doc, index: number) {
  const sceneIds = state.projectBefore.getDocsOfType(doc, 'novel_scene');
  if (!sceneIds.length) return;
  [parent, index] = getBookPartIndex(state, parent, index);

  getPlots(state).forEach(plot => {
    if (state.handledPlots.has(plot.id)) return;
    const children = state.projectBefore.getChildren(plot.id);
    const x = children && children.indexOf(parent);
    if (!children || x === -1) return;
    const beforeId = parent.children[index - 1];
    const indexOffset = beforeId ? plot.grid[x].indexOf(beforeId) + 1 : 1;
    const grid = plot.grid.map(col => col.slice()); // clone grid
    sceneIds.forEach((sceneId, i) => {
      const y = indexOffset + i;
      const addedRow = addDocToGrid(state.projectPatch, plot, grid, x, y, sceneId);
      if (addedRow) {
        grid.forEach(col => col.splice(y, 0, null));
      }
    });
  });
}

function moveBookPart(state: State, doc: Doc, fromParent: Doc, fromIndex: number, toParent: Doc, toIndex: number) {
  const sceneIds = state.projectBefore.getDocsOfType(doc, 'novel_scene');
  // parent will be the same book-scenes doc because this movement is within the same book
  const [parent, fromIndexStart, fromIndexEnd, toIndexStart, toIndexEnd] = getBookPartData(
    state,
    fromParent,
    fromIndex,
    toParent,
    toIndex
  );

  if (fromIndexStart === toIndexStart) return;

  getPlots(state).forEach(plot => {
    if (state.handledPlots.has(plot.id)) return;
    const children = state.projectBefore.getChildren(plot.id);
    const x = children && children.indexOf(parent);
    if (!children || x === -1) return;

    let fromY, toY;
    const fromGridStart = plot.grid[x].indexOf(parent.children[fromIndexStart]);
    const fromGridEnd = plot.grid[x].indexOf(parent.children[fromIndexEnd]);
    const toGridStart = plot.grid[x].indexOf(parent.children[toIndexStart]);
    const toGridEnd = plot.grid[x].indexOf(parent.children[toIndexEnd]);

    // Grid index change
    if (fromIndexStart < toIndexStart) {
      fromY = fromGridStart;
      toY = toGridEnd;
    } else {
      toY = toGridStart;
      fromY = fromGridEnd;
    }

    // Update the grid by moving the whole section from start to end scene
    for (let i = 0; i < sceneIds.length; i++) {
      for (let x = 0; x < plot.grid.length; x++) {
        state.projectPatch.patch.move(`/docs/${plot.id}/grid/${x}/${fromY}`, `/docs/${plot.id}/grid/${x}/${toY}`);
      }
    }

    // Update the lines
    for (let i = 1; i < plot.grid.length; i++) {
      const line = children[i];
      const points = plot.grid[i].slice(fromGridStart, fromGridEnd + 1).filter(Boolean);
      if (!points.length) continue;
      let from, to;

      if (fromY < toY) {
        from = line.children.indexOf(points[0]);
        to = toGridEnd;
        while (!plot.grid[i][to] && to > fromY) to--;
        to = line.children.indexOf(plot.grid[i][to]);
        if (to < from + points.length || to === -1) continue;
      } else {
        from = line.children.indexOf(points[points.length - 1]);
        to = toGridStart;
        while (!plot.grid[i][to] && to < fromY) to++;
        to = line.children.indexOf(plot.grid[i][to]);
        if (to > from - points.length || to === -1) continue;
      }

      for (let i = 0; i < points.length; i++) {
        state.projectPatch.patch.move(`/docs/${line.id}/children/${from}`, `/docs/${line.id}/children/${to}`);
      }
    }
  });
}

/**
 * When a book is deleted the link is removed and the column should be removed from the grid. Same with plot lines.
 */
function removeColumn(state: State, column: Doc, plot: PlotDoc, trimRows = false) {
  const children = state.projectBefore.getChildren(plot.id);
  const bookScenes = children.length > plot.children.length ? children[0] : null;
  const index = children ? children.indexOf(column) : -1;
  if (index === -1) return;

  state.projectPatch.patch.remove(`/docs/${plot.id}/grid/${index}`);

  // Shorten the row count if removing this column makes the grid smaller
  const without = plot.grid.slice();
  without.splice(index, 1);
  if (trimRows) shortenRows(state.projectPatch.patch, plot.id, without);

  // Remove links
  if (column.type === TYPE_BOOK_SCENES) {
    for (let y = 1; y < plot.grid[0].length; y++) {
      const sceneId = plot.grid[0][y];
      if (!sceneId) continue;
      for (let x = 1; x < plot.grid.length; x++) {
        const pointId = plot.grid[x][y];
        if (pointId) {
          state.projectPatch.unlinkDocs(pointId, 'plot', sceneId);
        }
      }
    }
  } else if (bookScenes) {
    for (let y = 1; y < plot.grid[index].length; y++) {
      const pointId = plot.grid[index][y];
      const sceneId = plot.grid[0][y];
      if (pointId && sceneId) {
        state.projectPatch.unlinkDocs(pointId, 'plot', sceneId);
      }
    }
  }
}

/**
 * When a column added.
 */
function addColumn(state: State, column: Doc, plot: PlotDoc, index: number) {
  const children = state.projectBefore.getChildren(plot.id);
  const bookScenes = children.length > plot.children.length ? children[0] : null;
  if (bookScenes && column.type !== TYPE_BOOK_SCENES) index++;
  const id = column.type === TYPE_BOOK_SCENES ? column.id.split('-')[0] : column.id;
  const gridColumn = [id].concat(column.children);

  // Make the columns longer/shorter as needed
  if (plot.grid[0]) {
    if (plot.grid[0].length > gridColumn.length) {
      while (gridColumn.length < plot.grid[0].length) {
        gridColumn.push(null);
      }
    } else if (plot.grid[0].length < gridColumn.length) {
      lengthenRows(state.projectPatch.patch, plot.id, plot.grid, gridColumn.length);
    }
  }

  state.projectPatch.patch.add(`/docs/${plot.id}/grid/${index}`, gridColumn);

  // Add links
  if (column.type === TYPE_BOOK_SCENES) {
    column.children.forEach((sceneId, i) => {
      plot.grid.forEach(col => {
        const pointId = col[i + 1];
        if (pointId) {
          state.projectPatch.linkDocs(pointId, 'plot', sceneId);
        }
      });
    });
  } else if (bookScenes) {
    column.children.forEach((pointId, i) => {
      const sceneId = plot.grid[0][i + 1];
      if (sceneId) {
        state.projectPatch.linkDocs(pointId, 'plot', sceneId);
      }
    });
  }
}

/**
 * When a book is added or linked and the column should be added to the grid.
 */
function addBookScenes(state: State, plot: PlotDoc, book: Doc) {
  const bookScenes: Doc = state.projectBefore.getDoc(getBookScenesId(book));
  addColumn(state, bookScenes, plot, 0);
}

/**
 * When a column is moved within the same plot grid.
 */
function moveColumn(state: State, column: Doc, oldIndex: number, newIndex: number) {
  const plot = state.projectBefore.getParent(column.id);
  if (!plot || plot.type !== 'novel_plot') return;
  if (state.projectBefore.linksFrom(plot.id, 'plot', true).length) {
    oldIndex++;
    newIndex++;
  }
  state.projectPatch.patch.move(`/docs/${plot.id}/grid/${oldIndex}`, `/docs/${plot.id}/grid/${newIndex}`);
}

/**
 * When a scene or plot point is removed.
 */
function removeDoc(state: State, doc: Doc, parent: Doc, index: number) {
  [parent, index] = getColumnDocAndIndex(state, parent, index);
  if (!parent || index === -1) return;

  getPlots(state).forEach(plot => {
    if (state.handledPlots.has(plot.id)) return;
    plot.grid.forEach((col, x) => {
      col.forEach((docId, y) => {
        if (doc.id === docId) removeDocFromGrid(state.projectPatch, plot, plot.grid, x, y, doc.id);
      });
    });
  });
}

/**
 * When a scene or plot point is added.
 */
function addDoc(state: State, doc: Doc, parent: Doc, index: number) {
  [parent, index] = getColumnDocAndIndex(state, parent, index);
  if (!parent || index === -1) return;

  getPlots(state).forEach(plot => {
    if (state.handledPlots.has(plot.id)) return;
    const children = state.projectBefore.getChildren(plot.id);
    const x = children && children.map(c => c.id).indexOf(parent.id);
    if (!children || x === -1) return;
    const beforeId = parent.children[index - 1];
    const y = beforeId ? plot.grid[x].indexOf(beforeId) + 1 : 1; // 0 is the column id
    addDocToGrid(state.projectPatch, plot, plot.grid, x, y, doc.id);
  });
}

function getColumnDocAndIndex(state: State, parent: Doc, index: number): [Doc?, number?] {
  if (parent && bookTypes.has(parent.type)) {
    const book = state.projectBefore.closest(parent, 'novel_book');
    if (book) {
      const sceneIdsBefore = getDocsUntil(state.projectBefore, book, 'novel_scene', parent);
      const sceneChildren = state.projectBefore
        .getChildren(parent.id)
        .slice(0, index)
        .filter(child => child.type === 'novel_scene');
      const bookScenesIndex = sceneIdsBefore.length + sceneChildren.length;
      const bookScenes = state.projectBefore.getDoc(getBookScenesId(book));
      return [bookScenes, bookScenesIndex];
    }
  } else if (parent && parent.type === 'novel_plot_line') {
    return [parent, index];
  }
  return [];
}

function getBookPartIndex(state: State, parent: Doc, index: number): [Doc, number] {
  const book = state.projectBefore.closest(parent, 'novel_book');
  const bookScenes = state.projectBefore.getDoc(getBookScenesId(book));
  const { docs } = state.projectBefore.get().project;
  let before: Doc;
  let inclusive = true;
  if (index === 0) {
    before = parent;
    inclusive = false;
  } else {
    before = docs[parent.children[index - 1]];
  }
  const startIndex = getDocsUntil(state.projectBefore, book, 'novel_scene', before, inclusive).length;
  return [bookScenes, startIndex];
}

// Make this correct
function getBookPartData(
  state: State,
  fromParent: Doc,
  fromIndex: number,
  toParent: Doc,
  toIndex: number
): [Doc, number, number, number, number] {
  const book = state.projectBefore.closest(fromParent, 'novel_book');
  const bookScenes = state.projectBefore.getDoc(getBookScenesId(book));
  const bookAfter = state.projectAfter.getDoc(book.id);
  const toParentAfter = state.projectAfter.getDoc(toParent.id);
  const { docs: afterDocs } = state.projectAfter.get().project;

  // From Info
  let doc = state.projectBefore.getDoc(fromParent.children[fromIndex]);
  let before = state.projectBefore.getDoc(fromParent.children[fromIndex]);
  const fromStartIndex = getDocsUntil(state.projectBefore, book, 'novel_scene', before).length;
  const fromEndIndex = fromStartIndex + state.projectBefore.getDocsOfType(doc, 'novel_scene').length - 1;

  // To Info (get info from the project after the patch is applied)
  doc = afterDocs[afterDocs[toParent.id].children[toIndex]];
  before = afterDocs[toParentAfter.children[toIndex]];
  const toStartIndex = getDocsUntil(state.projectAfter, bookAfter, 'novel_scene', before).length;
  const toEndIndex = toStartIndex + state.projectAfter.getDocsOfType(doc, 'novel_scene').length - 1;

  return [bookScenes, fromStartIndex, fromEndIndex, toStartIndex, toEndIndex];
}

// Get all doc ids of a certain type until the given doc, optionally including the docs inside
function getDocsUntil(
  projectStore: ProjectStore,
  within: Doc,
  type: string,
  until: Doc,
  includeChildren?: boolean
): string[] {
  if (within === until) return [];

  const { docs } = projectStore.get().project;
  const iterator = createDocIterator(docs, within);
  const ofType: string[] = [];
  let doc;
  let gatherChildren = false;

  while ((doc = iterator.next())) {
    if (gatherChildren && !projectStore.contains(until, doc)) {
      break;
    }

    if (doc === until) {
      if (includeChildren) {
        gatherChildren = true;
      } else {
        break;
      }
    }

    if (doc.type === type) {
      ofType.push(doc.id);
    }
  }

  return ofType;
}

function removeDocFromGrid(projectPatch: ProjectPatch, plot: PlotDoc, grid: Grid, x: number, y: number, docId: string) {
  if (grid.some((col, i) => i !== x && col[y])) {
    removeDocOnRow(projectPatch.patch, plot.id, x, y);
    removeLinks(projectPatch, plot, x, y, docId);
    return false;
  } else {
    removeDocWithRow(projectPatch.patch, plot.id, grid.length, x, y);
    return true;
  }
}

function removeDocOnRow(patch: JSONPatch, plotId: string, x: number, y: number) {
  patch.replace(`/docs/${plotId}/grid/${x}/${y}`, null);
}

function removeDocWithRow(patch: JSONPatch, plotId: string, columnCount: number, x: number, y: number) {
  for (let i = 0; i < columnCount; i++) {
    patch.remove(`/docs/${plotId}/grid/${i}/${y}`);
  }
}

function addDocToGrid(projectPatch: ProjectPatch, plot: PlotDoc, grid: Grid, x: number, y: number, docId: string) {
  // If the spot is filled or the grid doesn't reach that far, add a row, othewise add the doc to the cell
  if (grid[x][y] || grid[x].length === y) {
    addDocWithRow(projectPatch.patch, plot.id, grid.length, x, y, docId);
    return true;
  } else {
    addDocOnRow(projectPatch.patch, plot.id, x, y, docId);
    addLinks(projectPatch, plot, x, y, docId);
    return false;
  }
}

function addDocOnRow(patch: JSONPatch, plotId: string, x: number, y: number, docId: string) {
  patch.replace(`/docs/${plotId}/grid/${x}/${y}`, docId);
}

function addDocWithRow(patch: JSONPatch, plotId: string, columnCount: number, x: number, y: number, docId: string) {
  for (let i = 0; i < columnCount; i++) {
    patch.add(`/docs/${plotId}/grid/${i}/${y}`, x === i ? docId : null);
  }
}

function addLinks(projectPatch: ProjectPatch, plot: PlotDoc, x: number, y: number, docId: string) {
  // docId needs to be linked with the scene or plot point
  const plotLinked = Object.keys(projectPatch.project.links).some(key => key.startsWith(`${plot.id}:plot:`));
  if (plotLinked) {
    if (x === 0) {
      for (let i = 1; i < plot.grid.length; i++) {
        const linkFrom = plot.grid[i][y];
        if (linkFrom) {
          projectPatch.linkDocs(linkFrom, 'plot', docId);
        }
      }
    } else {
      const linkTo = plot.grid[0][y];
      if (linkTo) {
        projectPatch.linkDocs(docId, 'plot', linkTo);
      }
    }
  }
}

function removeLinks(projectPatch: ProjectPatch, plot: PlotDoc, x: number, y: number, docId: string) {
  // docId needs to be linked with the scene or plot point
  const plotLinked = Object.keys(projectPatch.project.links).some(key => key.startsWith(`${plot.id}:plot:`));
  const linkMap = projectPatch.project.links;
  if (plotLinked) {
    if (x === 0) {
      for (let i = 1; i < plot.grid.length; i++) {
        const linkFrom = plot.grid[i][y];
        const linkKey = `${linkFrom}:plot:${docId}`;
        if (linkMap[linkKey]) {
          projectPatch.unlinkDocs(linkFrom, 'plot', docId);
        }
      }
    } else {
      const linkTo = plot.grid[0][y];
      const linkKey = `${docId}:plot:${linkTo}`;
      if (linkMap[linkKey]) {
        projectPatch.unlinkDocs(docId, 'plot', linkTo);
      }
    }
  }
}

function shortenRows(patch: JSONPatch, plotId: string, grid: Grid) {
  if (!grid.length) return;

  // Leave a couple rows
  const maxLength = getMaxColumnLength(grid) + 2;

  if (maxLength < grid[0].length) {
    for (let x = 0; x < grid.length; x++) {
      for (let y = grid[x].length - 1; y >= maxLength; y--) {
        patch.remove(`/docs/${plotId}/grid/${x}/${y}`);
      }
    }
  }
}

function lengthenRows(patch: JSONPatch, plotId: string, grid: Grid, length: number) {
  if (!grid.length) return;

  for (let x = 0; x < grid.length; x++) {
    let y = grid[x].length;
    while (y < length) {
      patch.add(`/docs/${plotId}/grid/${x}/${y++}`, null);
    }
  }
}

function getMaxColumnLength(grid: Grid) {
  let maxLength = 0;
  grid.forEach(col => {
    let lastEntryIndex = col.length;
    while (lastEntryIndex-- > 0) {
      if (col[lastEntryIndex]) break;
    }
    maxLength = Math.max(maxLength, lastEntryIndex + 1);
  });
  return maxLength;
}

function getPlots(state: State) {
  return Object.values(state.projectBefore.get().project.docs).filter(
    doc => doc.type === 'novel_plot' && !state.deletedDocs[doc.id] && !state.unchangedGrids[doc.id]
  ) as PlotDoc[];
}

function getBookScenesId(book: string | Doc) {
  return `${typeof book === 'string' ? book : book.id}-scenes`;
}

function createDocIterator(docs: ProjectDocs, within: Doc) {
  type StackEntry = { parent: Doc; i: number };
  const stack: StackEntry[] = [];
  let current: Doc = null;
  let parent = within;
  let i = 0;

  function next(): Doc {
    if (!parent) return (current = null);
    if (current && current.children && current.children.length) {
      stack.push({ parent, i });
      parent = current;
      i = 0;
    } else {
      while (parent && parent.children.length <= i) {
        const entry = stack.pop();
        parent = entry && entry.parent;
        i = entry && entry.i;
      }
    }
    if (!parent) return (current = null);
    if (!docs[parent.children[i]]) {
      console.log('Index invalid in parent:', parent);
      i++;
      return next();
    }
    return (current = docs[parent.children[i++]]);
  }

  return {
    get current() {
      return current;
    },
    get parent() {
      return parent;
    },
    next,
  };
}
