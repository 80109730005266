import { Image, getImage } from '@dabble/plugins/content/images';
import {
  AlignmentType,
  BorderStyle,
  File as DocxFile,
  Footer as DocxFooter,
  Header as DocxHeader,
  Paragraph as DocxParagraph,
  ExternalHyperlink,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  IFloating,
  IHeaderOptions,
  IParagraphOptions,
  IParagraphStyleOptions,
  IParagraphStylePropertiesOptions,
  IRunStylePropertiesOptions,
  ISectionOptions,
  ISectionPropertiesOptions,
  IStylesOptions,
  ImageRun,
  Packer,
  PageBreak,
  TextRun,
  TextWrappingType,
  UnderlineType,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
} from 'docx';
import saveAs from 'file-saver';
import { AttributeMap, Delta } from 'typewriter-editor';
import {
  ExportData,
  ExportFile,
  ExportFileConstructor,
  Footer,
  Header,
  ParagraphOptions,
  Section,
  Style,
  Styles,
} from '../types';
import { editor } from '../utils';
import { numbering } from './word-bullet-styles';

const TWIP = 20;
const PAGE_BREAK = new DocxParagraph({ children: [new PageBreak()] });
const OUTSET_LEFT: IFloating = {
  horizontalPosition: {
    relative: HorizontalPositionRelativeFrom.MARGIN,
    align: HorizontalPositionAlign.LEFT,
  },
  verticalPosition: {
    relative: VerticalPositionRelativeFrom.LINE,
    align: VerticalPositionAlign.TOP,
  },
  wrap: {
    type: TextWrappingType.SQUARE,
  },
};
const FULL_WIDTH_IMAGE = {
  horizontalPosition: {
    relative: HorizontalPositionRelativeFrom.PAGE,
    offset: 0,
  },
  verticalPosition: {
    relative: VerticalPositionRelativeFrom.LINE,
    align: VerticalPositionAlign.TOP,
  },
};
const NUMBERING = {
  a: 'lowerLetter',
  A: 'upperLetter',
  i: 'lowerRoman',
  I: 'upperRoman',
  '1': 'numeric',
};

export class WordFile implements ExportFile {
  styles = new WordStyles();
  sections: ISectionOptions[] = [new WordSection()];

  constructor(public data: ExportData) {
    this.styles.style('Horizontal Rule').lineSpacing(0).spaceAbove(12).spaceBelow(24);
  }

  getPage(): NotReadOnly<ISectionPropertiesOptions['page']> {
    const props =
      (this.sections.at(-1).properties as any) || ((this.sections.at(-1).properties as ISectionPropertiesOptions) = {});
    if (!props.page) props.page = {};
    return props.page;
  }

  setPageSize(width: number, height: number): this {
    this.getPage().size = { width: width * TWIP, height: height * TWIP };
    return this;
  }

  pageNumbersStart(start: number): this {
    this.getPage().pageNumbers = { start };
    return this;
  }

  addSection() {
    const section = new WordSection();
    this.sections.push(section);
    return section;
  }

  addParagraph(options: ParagraphOptions, content: Delta | string): this {
    const p = getParagraphs(options, content);
    (this.sections.at(-1).children as DocxParagraph[]).push(...p);
    return this;
  }

  addPageBreak(): this {
    (this.sections.at(-1).children as DocxParagraph[]).push(PAGE_BREAK);
    return this;
  }

  async package() {
    // Get rid of trailing page break
    if (this.sections.at(-1).children.at(-1) === PAGE_BREAK) {
      (this.sections.at(-1) as any as NotReadOnly<ISectionOptions>).children.pop();
    }

    const wordDoc = new DocxFile({
      creator: this.data.author,
      title: this.data.title,
      styles: this.styles,
      sections: this.sections,
      numbering,
    });

    return await Packer.toBlob(wordDoc);
  }

  async save(): Promise<any> {
    saveAs(await this.package(), `${this.data.filename}.docx`);
  }
}

class WordSection implements Section, ISectionOptions {
  headers?: { default?: DocxHeader };
  footers?: { default?: DocxFooter };
  children: DocxParagraph[] = [];

  addHeader(): Header {
    if (!this.headers) this.headers = {};
    const header = new WordHeaderFooter();
    (this.headers.default as any) = header;
    return header;
  }

  addFooter(): Header {
    if (!this.footers) this.footers = {};
    const header = new WordHeaderFooter();
    (this.footers.default as any) = header;
    return header;
  }
}

class WordHeaderFooter extends DocxFooter implements Header, Footer {
  options: IHeaderOptions = { children: [] };

  addParagraph(options: ParagraphOptions, content: Delta | string): this {
    const p = getParagraphs(options, content);
    (this.options.children as DocxParagraph[]).push(...p);
    return this;
  }
}

class WordStyles implements Styles, IStylesOptions {
  paragraphStyles: IParagraphStyleOptions[] = [];

  style(name: string, basedOn?: string): Style {
    if (!basedOn && name !== 'Normal') basedOn = 'Normal';
    const style = new WordDocStyle(name, basedOn);
    this.paragraphStyles.push(style);
    return style;
  }
}

class WordDocStyle implements Style, IParagraphStyleOptions {
  id: string;
  name: string;
  basedOn?: string;
  quickFormat = true;
  paragraph: NotReadOnly<IParagraphStylePropertiesOptions> = {};
  run: NotReadOnly<IRunStylePropertiesOptions> = {};
  next?: string;

  constructor(name: string, basedOn?: string) {
    this.id = getId(name);
    this.name = name;
    this.basedOn = getId(basedOn);
  }

  defaultNext(value: string): this {
    this.next = getId(value);
    return this;
  }

  // PARAGRAPH STYLES

  alignment(value: 'start' | 'center' | 'end' | 'justified'): this {
    this.paragraph.alignment = (value === 'justified' ? 'both' : value);
    return this;
  }

  indent(value: number, side?: 'start' | 'end' | 'firstLine'): this {
    const prop = side === 'end' ? 'right' : side === 'firstLine' ? 'firstLine' : 'left';
    if (!this.paragraph.indent) this.paragraph.indent = {};
    this.paragraph.indent[prop] = value * TWIP;
    return this;
  }

  keepLinesTogether(value: boolean = true): this {
    this.paragraph.keepLines = value;
    return this;
  }

  keepWithNext(value: boolean = true): this {
    this.paragraph.keepNext = value;
    return this;
  }

  lineSpacing(value: number): this {
    if (!this.paragraph.spacing) this.paragraph.spacing = {};
    this.paragraph.spacing.line = value * 12 * TWIP;
    return this;
  }

  spaceAbove(value: number): this {
    if (!this.paragraph.spacing) this.paragraph.spacing = {};
    this.paragraph.spacing.before = value * TWIP;
    return this;
  }

  spaceBelow(value: number): this {
    if (!this.paragraph.spacing) this.paragraph.spacing = {};
    this.paragraph.spacing.after = value * TWIP;
    return this;
  }

  tabStop(value: number, alignment?: 'start' | 'end'): this {
    const prop = alignment === 'end' ? 'rightTabStop' : 'leftTabStop';
    this.paragraph[prop] = value * TWIP;
    return this;
  }

  // TEXT STYLES

  backgroundColor(value: string | null): this {
    if (value) this.run.highlight = hexToColor(value);
    else delete this.run.highlight;
    return this;
  }

  bold(value: boolean = true): this {
    if (value) this.run.bold = value;
    else delete this.run.bold;
    return this;
  }

  color(value: string): this {
    if (value) this.run.color = hexToColor(value);
    else delete this.run.color;
    return this;
  }

  fontFamily(value: string, weight: number = 400): this {
    if (value) this.run.font = value;
    else delete this.run.font;
    if (weight > 500) this.bold(true);
    return this;
  }

  fontSize(value: number): this {
    if (value) this.run.size = value * 2;
    else delete this.run.size;
    return this;
  }

  italic(value: boolean = true): this {
    if (value) this.run.italics = value;
    else delete this.run.italics;
    return this;
  }

  smallCaps(value: boolean = true): this {
    if (value) this.run.smallCaps = value;
    else delete this.run.smallCaps;
    return this;
  }

  strikeThrough(value: boolean = true): this {
    if (value) this.run.strike = value;
    else delete this.run.strike;
    return this;
  }

  underline(value: boolean = true): this {
    if (value) this.run.underline = { type: UnderlineType.SINGLE, color: 'auto' };
    else delete this.run.underline;
    return this;
  }
}

function hexToColor(value: string) {
  return value.replace(/^#/, '');
}

function getId(name?: string) {
  return name?.replace(/ /g, '');
}

function getTextRunStyles(attributes: AttributeMap): IRunStylePropertiesOptions {
  const runOptions: NotReadOnly<IRunStylePropertiesOptions> = {};
  if (!attributes) return runOptions;
  const formats = editor.typeset.formats;
  Object.keys(attributes)
    .sort((a, b) => formats.priority(b) - formats.priority(a))
    .forEach(name => {
      if (name === 'link') return; // Handled by addParagraph
      const value = attributes[name];
      if (name === 'italic') runOptions.italics = value;
      else if (name === 'del') runOptions.strike = value; // Remove this when moving to editor comments
      else if (name === 'highlight' || name === 'bold' || name === 'strike' || name === 'underline') (runOptions as any)[name] = value;
    });

  return runOptions;
}

function getParagraphOptions(options?: ParagraphOptions) {
  // see https://docx.js.org/#/usage/numbering?id=bullets-and-numbering
  const opts: IParagraphOptions = { style: getId(options?.style) };
  const writeOpts = opts as any as NotReadOnly<IParagraphOptions>;
  if (options.hr) {
    writeOpts.border = { bottom: { color: '#cccccc', style: BorderStyle.SINGLE } };
    writeOpts.style = getId('Horizontal Rule');
  } else if (options.list) {
    if (options.list.kind === 'bullet') {
      writeOpts.bullet = { level: options.list.indent || 0 };
    } else {
      writeOpts.numbering = {
        level: options.list.indent || 0,
        reference: NUMBERING[options.list.type || '1'],
      };
    }
  } else if (options.image) {
    const image = options.image as Image;
    const { style = 'inset-center' } = options.image;
    let floating: IFloating | undefined;

    if (style === 'full-page') {
      writeOpts.pageBreakBefore = true;
    } else if (style === 'fill-width') {
      floating = FULL_WIDTH_IMAGE;
    } else if (style === 'outset-left') {
      floating = OUTSET_LEFT;
    } else if (style === 'center') {
      writeOpts.alignment = AlignmentType.CENTER;
    }

    writeOpts.children = [
      new ImageRun({
        data: getImage(image) as unknown as ArrayBuffer, // Promise will resolve when packed
        transformation: {
          width: image.width / 0.75,
          height: image.height / 0.75,
        },
        floating,
      }),
    ];

    if (style === 'full-page') {
      writeOpts.children.push(new PageBreak());
    }
  }
  return opts;
}

function getParagraphs(options: ParagraphOptions, content: Delta | string) {
  const p = [new DocxParagraph(getParagraphOptions(options))];
  if (typeof content === 'string') {
    if (content.trim()) {
      const children = content.includes('[[') ? content.split(/(?:\[\[|\]\])/).filter(Boolean) : [content];
      p[0].addChildElement(new TextRun({ children }));
    }
  } else {
    let link: string = null;
    let parent: DocxParagraph | ExternalHyperlink = p[0];
    content.ops.forEach(op => {
      // Add text to paragraph or hyperlink
      if (op.attributes?.link && link !== op.attributes.link) {
        link = op.attributes.link;
        parent = new ExternalHyperlink({ link, children: [] });
      } else if (!op.attributes?.link && link) {
        link = null;
        parent = p[0];
      }
      if (typeof op.insert === 'string') {
        parent.addChildElement(new TextRun({ text: op.insert, ...getTextRunStyles(op.attributes) }));
      } else if (op.insert.br) {
        parent.addChildElement(new TextRun({ break: 1 }));
      }
    });
  }
  return p;
}

type NotReadOnly<T> = {
  -readonly [P in keyof T]: NotReadOnly<T[P]>;
};

export const File: ExportFileConstructor = WordFile;
