{
  "font_general": "General",
  "font_romance": "Romance",
  "font_thriller": "Thriller",
  "font_literary": "Literary",
  "font": "Font",
  "font_sans": "Sans Serif",
  "font_size_default": "(Default)",
  "font_save": "Save Formatting",
  "font_reset": "Reset all Defaults",
  "font_dyslexic": "Dyslexic",
  "font_double_spaced": "Double Spaced",
  "font_single_spaced": "Single Spaced",
  "font_half_spaced": "1 1/2 Spaced",
  "font_style_book": "Book Style",
  "font_style_book_modified": "Modified Book Style",
  "font_style_modern": "Modern Style",
  "font_times": "Times New Roman",
  "font_courier": "Courier",
  "font_comic": "Comic Sans",
  "font_screenplay": "Screenplay",
  "font_screenplay_sans": "Screenplay Sans Serif",
  "novel": "Novel",
  "novel_book": "Book",
  "novel_book_scenes": "Scenes",
  "novel_book_scenes_count": "({{count}})",
  "novel_book_untitled": "Untitled Book",
  "novel_book_unsubtitled": "Subtitle",
  "novel_book_word_count": "{{wordCount}} word",
  "novel_book_word_count_other": "{{wordCount}} words",
  "novel_doc_description": "{{type}} Notes",
  "novel_doc_notes": "Notes",
  "novel_plot_notes": "Plot Notes",
  "novel_formatting": "Formatting",
  "novel_font": "Formatting",
  "novel_merge_scene": "Merge Scene…",
  "novel_scene_added": "Scene Added",
  "novel_scene_added_body": "You successfully added a scene to this chapter.",
  "novel_scene_split": "Scene Split",
  "novel_scene_split_body": "You successfully split your scene.",
  "novel_scene_preview": "Scene Preview",
  "novel_scene_style": "Scene Paragraph Style",
  "novel_scene_spacing": "Scene Line Spacing",
  "novel_note_preview": "Note Preview",
  "novel_note_style": "Note Paragraph Style",
  "novel_note_spacing": "Note Line Spacing",
  "novel_cast": "Cast",
  "novel_cast_untitled": "Untitled Cast",
  "novel_chapter": "Chapter",
  "novel_image_delete": "Delete Image?",
  "novel_image_delete_message": "This will permanently delete this image. Would you like to continue?",
  "novel_image_unsupported": "Unsupported Filetype",
  "novel_image_unsupported_message": "Please enter a single image with the extension .png, .jpg, jpeg, .gif, .webp, or .tiff",
  "novel_title_image": "Set Title Image",
  "novel_title_image_remove": "Remove Title Image",
  "novel_image_drop_dialog_main_text": "Decorate your chapter or part title with an illustration, photograph, or other picture.",
  "novel_image_drop_dialog_sub_text": "",
  "novel_image_drop_text": "Drag your full page image here.",
  "novel_image_drop_sub_text": "(1:1.5 or 1:1.6 aspect ratio)",
  "novel_cover_art": "Set Cover Art",
  "novel_cover_art_remove_dialog_title": "Remove Cover?",
  "novel_cover_art_remove_dialog_text": "This will delete your cover completely. You can also update the image by dragging a new one over the old.",
  "novel_cover_art_remove": "Remove Cover Art",
  "novel_cover_art_drop_main_text": "A cover image is a great way to set your novel apart and will appear on the home screen and web sharing.",
  "novel_cover_art_drop_sub_text": "A good cover image should be 816px by 1056px",
  "novel_modal_drop_drag_text": "Drag your cover image here",
  "novel_modal_drop_or_divider": "OR",
  "novel_modal_drop_browse_button": "Browse Files",
  "novel_chapter_split": "Chapter Split",
  "novel_chapter_split_body": "You successfully split your chapter.",
  "novel_chapter_split_here": "Split chapter here",
  "novel_chapter_number": "Chapter {{number}}",
  "novel_chapter_numbering_off": "Convert from Chapter",
  "novel_chapter_numbering_off_confirm": "Convert from being a Chapter?\n\nDelete the title to turn this back into a Chapter.",
  "novel_character": "Character",
  "novel_characters": "Characters",
  "novel_main_characters": "Main Characters",
  "novel_secondary_characters": "Supporting Characters",
  "novel_world_building": "World Building",
  "novel_character_untitled": "Unnamed Character",
  "novel_delete_column": "Delete this column",
  "novel_delete_column_confirm": "Are you sure you want to delete this column and every point on it?",
  "novel_delete_row": "Delete this row",
  "novel_delete_row_confirm": "Are you sure you want to delete this row with every point on it?",
  "novel_delete_row_with_scene_confirm": "Are you sure you want to delete every point on this row?\n\nYou will need to delete the scene manually before you can remove the row.",
  "novel_delete_row_with_scene_only": "You must delete the scene manually before you can remove the row.\n\nDeleting the scene here removes it from the manuscript as well.",
  "novel_delete_row_with_scene_only_title": "Cannot Delete Yet",
  "novel_delete_scene_confirm": "Deleting a scene from the plot will delete it from the book.\n\nAre you sure you want to trash “{{-title}}”?",
  "novel_doc_descriptions": "Notes",
  "novel_page": "Note",
  "novel_page_untitled": "Untitled Note",
  "novel_folder": "Folder",
  "novel_image": "Image",
  "note_image": "Image",
  "novel_insert_row": "Insert row",
  "novel_manuscript": "Manuscript",
  "novel_notes": "Story Notes",
  "novel_part": "Part",
  "novel_part_number": "Part {{number}}",
  "novel_plot": "Plot Grid",
  "novel_plot_incorrect_title": "Plot Grid Error",
  "novel_plot_incorrect_body": "There is an error updating your plot grid in the background to stay in sync with this change just made.\n\nReverting the change.\n\nPlease contact support to get the issue resolved.",
  "novel_plot_incorrect_on_load": "There is an error updating your plot grid. \n\nPlease contact support to get the issue resolved.",
  "novel_plot_for": "Plot for {{-bookTitle}}",
  "novel_plot_untitled": "Untitled Plot Grid",
  "novel_plots": "Plot",
  "novel_plot_line": "Plot Line",
  "novel_plot_line_untitled": "Untitled Plot Line",
  "novel_plot_point": "Plot Point",
  "novel_plot_point_untitled": "Untitled Plot Point",
  "novel_scene": "Scene",
  "novel_scene_body_empty": "And then…",
  "novel_scene_body_first": "Once upon a time…",
  "novel_scene_description_empty": "A description of what happens in your scene…",
  "novel_scene_notes": "Scene Notes",
  "novel_scene_description": "Scene Description",
  "novel_scene_split_here": "Split scene here",
  "novel_scene_title_empty": "Untitled Scene",
  "novel_scenes": "Scenes",
  "novel_scenes_for": "Scenes for “{{-for}}”",
  "novel_scenes_merge_which": "What direction do you want to merge the scene?",
  "novel_scenes_merge_above": "Above",
  "novel_scenes_merge_below": "Below",
  "novel_title": "Title",
  "novel_author": "Author",
  "novel_title_page": "Book",
  "novel_untitled": "Untitled Novel",
  "read_to_me": "Read to Me",
  "add_title_image": "Add Title Image",
  "project_insert_hr": "Insert Horizontal Rule",
  "project_insert_dl": "Insert Property List",
  "project_insert_dlh": "Insert Property List Header",
  "export_to_web": "Share to Web",
  "share_to_web_header": "Share to Web",
  "share_to_web_documents": "Shared Documents",
  "shared_title": "Title",
  "shared_created": "Created On",
  "shared_expires": "Expires",
  "shared_type": "Type",
  "shared_novel_book": "Book",
  "shared_novel_part": "Part",
  "shared_novel_chapter": "Chapter",
  "shared_novel_scene": "Scene",
  "shared_set_expiration": "Set Expiration Date",
  "shared_delete": "Remove",
  "shared_view": "View",
  "shared_send_email": "Send by Email",
  "shared_modal_text": "<p>The selected document has been saved to the web.  You can manage access of this and all other shared documents in Project Settings.<p><p><strong>Anyone with this link can view the document.</strong></p>",
  "shared_email_header": "Send Shared Document by Email",
  "shared_email_text": "Send email to the addresses below:",
  "shared_add_email": "Email Address",
  "shared_add": "Add",
  "shared_no_email": "No Email Addresses",
  "shared_email_message": "Custom message you'd like to send",
  "shared_email_sent": "Shared with provided email addresses.",
  "request_share_to_web": "Creating your shareable link",
  "add_ribbon": "Add Ribbon",
  "edit_ribbon": "Edit Ribbon",
  "remove_ribbon": "Remove Ribbon",
  "plot_grid_header": "Plots",
  "plot_grid_create_generic": "Create a generic plot grid:",
  "plot_grid_create_new": "Create a New Plot Grid",
  "plot_grid_create_for_book": "Create a plot grid for one of your books:",
  "plot_grid_introducing": "Introducing Plots",
  "plot_grid_explanation": "<p>In Dabble a <strong>Plot Grid</strong> is made up of <strong>Plot Lines</strong> and <strong>Plot Points</strong>.</p>",
  "plot_grid_grid": "Plot Grid",
  "plot_grid_a_plot_grid": "A plot grid:",
  "plot_grid_bullet_points": "<li>gives an overview of multiple plot lines</li><li>lines up plot points with each other</li><li>attaches plot points to scenes</li>",
  "plot_grid_plot_line": "Plot Line",
  "plot_grid_plot_line_explanation": "<p>A Plot Line focuses on a single subplot.</p><p>Each Plot Point in a Plot Line is represented by an index card with a title and description. You can fill in as much detail about each as you need.</p>",
  "plot_grid_plot_point": "Plot Point",
  "plot_grid_plot_grid_plot_point_explanation": "<p>Plot Points represent a single event within your Plot Line.</p><p>Plot Points can be attached to scenes by lining them up in Plot Grids. Once done, you will see them in the right-hand column while you work on your scene, allowing you to keep track of what must happen in that scene.</p>",
  "novel_export_failed": "Export Failed",
  "book_options": "Book Options",
  "export_images_options": "Image Options",
  "gdoc_error_connect_google": "Not connected to Google",
  "gdoc_inform_exporting": "Starting Google Export",
  "project_by": "by"
}
