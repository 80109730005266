{
  "app_cache": "Gestión de Caché",
  "app_cache_clear": "Borrar Caché de Aplicaciones",
  "app_cache_clear_description": "Elimine los archivos de la aplicación Dabble de este dispositivo, vuelva a cargarlos y vuelva a instalarlos.",
  "app_cache_image_clear": "Borrar Caché de Imagen",
  "app_cache_image_clear_description": "Elimina tus archivos de imagen de este dispositivo. Se almacenarán en caché la próxima vez que se vean.",
  "app_cache_images_cleared": "Se ha borrado la caché de imágenes",
  "app_db_delete": "Eliminar Base de Datos",
  "app_db_delete_description": "Elimine su base de datos local de este dispositivo, vuelva a cargar, vuelva a crear y sincronice (si está en línea).",
  "data_clear_space": "¡Libera espacio en tu computadora! Dabble necesita más espacio que este. Por favor.",
  "data_used_available": "<strong>{{used}}</strong> usado / <strong>{{disponible}}</strong> disponible",
  "data_used_header": "Datos Utilizados",
  "data_export_db": "Exportar Base de Datos",
  "data_export_db_description": "Realice una copia de seguridad de toda la base de datos local en un archivo JSON para su posterior recuperación.",
  "data_export_db_header": "Exportar toda la base de datos local",
  "data_export_description": "Copia de seguridad de datos puros de proyectos desde su máquina local o nuestro servidor. Realmente solo es útil para la recuperación ante desastres. Pero si eres del tipo demasiado cauteloso...",
  "data_export_header": "Exportar Datos del Proyecto",
  "data_export_local": "Exportar Desde Local",
  "data_export_server": "Exportar Desde el Servidor",
  "data_export_slow": "Esto puede tardar un minuto.",
  "data_import_db": "Importar Base de Datos",
  "data_management": "Gestión de Datos",
  "data_select_project": "Selecciona un proyecto…",
  "data_disconnected": "¡Tienes que estar conectado a Internet para exportar los datos del servidor! Y Dabble no lo es. Conectado. Ahora mismo.",
  "save_error": "Error al Guardar",
  "save_error_message": "Hay un error con tus datos. Póngase en contacto con el soporte para no perder ningún progreso.",
  "save_pending": "Cambios pendientes",
  "save_saved": "Guardado localmente",
  "save_saving": "Guardando…",
  "syncing_syncing": "Sincronización",
  "syncing_complete": "Sincronizado con su cuenta",
  "syncing_offline": "Desconectada",
  "syncing_error": "Sincronización de errores: {{error}}",
  "syncing_error_message": "Hubo un error de sincronización. Si refrescarse/reiniciar no lo arregla, comuníquese con el soporte. \nError: {{- error}}",
  "syncing_off": "Sincronización desactivada",
  "sync_status": "El estado actual de guardado y sincronización de su proyecto",
  "update_apply": "Aplicar Actualización",
  "update_installed": "Se ha instalado una actualización.",
  "update_message": "Se requiere una actualización obligatoria de Dabble {{version}}.",
  "update_required": "Actualización requerida",
  "update_restart": "Reiniciar Dabble",
  "update_updating": "Actualizando…",
  "update_wait": "Espere mientras se descarga e instala una actualización."
}
