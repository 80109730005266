{
  "CONNECTION_CLOSED": "La conexión del servidor se cayó temporalmente.",
  "DISCONNECTED": "Dabble no está conectado al servidor.",
  "about": "Acerca",
  "actions_none": "Sin acciones",
  "add_new_document": "Agregar Nuevo $t({{type}})",
  "novel_prologue": "Prólogo",
  "add_document": "Agregar $t({{type}})",
  "author_unknown": "Autor desconocido",
  "back_home": "Página de Inicio",
  "back_project": "Volver al Proyecto",
  "blog": "Blog",
  "cancel": "Cancelar",
  "clear_dictionary": "Eliminar Todo",
  "confirm": "Confirmar",
  "context_menu_paste_permission_title": "",
  "context_menu_paste_permission_denied": "Previamente ha bloqueado Dabble para que no pueda pegar.\n\nSiga las instrucciones en:\n\nhttp://help.dabblewriter.com/en/articles/3951648-allow-dabble-to-paste-on-right-click\n\nVuelva a intentarlo.",
  "context_menu_paste_permission_prompt": "Se requiere permiso para pegar desde el menú contextual de Dabble.\n\nHaga clic en \"Permitir\" cuando se le pregunte si Dabble puede pegar para ti.",
  "context_menu_paste_permission_unsupported": "Este navegador no admite pegar desde el menú contextual personalizado de Dabble.\n\nUtilice el método abreviado de teclado:\n\n{{shortcut}}",
  "context_menu_paste_book_exception_title": "Pegar un Libro?",
  "context_menu_paste_book_exception_text": "Parece que estás tratando de pegar un libro en Dabble. Utilice el método abreviado de teclado en su lugar.\n\natajo de teclado: {{shortcut}}",
  "counts_pages": "Página",
  "counts_pages_other": "Páginas",
  "counts_pages_tooltip": "Estimación basada en 250 palabras por página",
  "counts_selection": "Palabra seleccionada",
  "counts_selection_other": "Palabras seleccionadas",
  "counts_selection_tooltip": "Número de palabras seleccionadas",
  "counts_today": "Hoy Dia",
  "counts_today_tooltip": "Cambio total de palabras para este documento hoy",
  "counts_words": "Palabra",
  "counts_words_other": "Palabras",
  "counts_words_tooltip": "Recuento de palabras de este documento",
  "dabble_community": "Comunidad Dabble",
  "delete": "Borrar",
  "delete_item": "Borrar",
  "delete_permanently": "Borrar permanentemente",
  "delete_permanently_confirm": "¿Estás segura de que quieres eliminar permanentemente “{{-title}}”?",
  "delete_permanently_confirm_children": "¿Estás segura de que quieres eliminar permanentemente “{{-title}}” y todas sus hijos?",
  "delete_confirm": "¿Estás seguro de que quieres eliminar? “{{-title}}”?",
  "dialog_cancel": "Cancelar",
  "dialog_no": "No",
  "dialog_ok": "OK",
  "dialog_yes": "Sí",
  "document_copy": "Copiar $t({{type}})",
  "document_paste": "Pegar",
  "document_paste_doc": "Pegar $t({{type}})",
  "edit": "Editar",
  "edit_title": "Renombrar $t({{type}})",
  "convert_from_prologue": "Convertir a Capítulo",
  "empty_trash": "Vacía la Papelera",
  "empty_trash_confirm": "¿Está seguro de que desea eliminar permanentemente todo lo que está en la papeler?",
  "error": "Error",
  "feature_coauthoring": "Coautoría",
  "feature_comments": "Comentarios",
  "feature_dark_mode": "Modo Oscuro",
  "feature_focus": "Enfocar",
  "feature_goals": "Goles y Estadísticas",
  "feature_grammar": "Gramática",
  "feature_images": "Imágenes",
  "feature_not_available": "{{feature}} Indisponible",
  "feature_plotting": "Trazar",
  "feature_spell_check": "Corrector Ortográfico",
  "feature_stickies": "Notas Adhesivas",
  "feature_story_notes": "Notas de la Historia",
  "feature_support_email": "Soporte de Email",
  "feature_support_center": "Centro de Soporte",
  "feature_support_chat": "Chat de Soporte",
  "feature_upgrade_now": "Actualizar Ahora",
  "feature_upgrade": "La función de {{feature}} no está disponible en este plan. Actualice para usar.",
  "feature_versioning": "Versionado",
  "file": "Archivar",
  "file_untitled": "Archivo sin Título",
  "filter_placeholder": "Filtrar archivos de proyecto…",
  "filter_tooltip": "Filtre sus archivos por el texto que contienen.",
  "focus_disable": "Deshabilitar el modo de enfoque",
  "focus_enable": "Habilitar el modo de enfoque",
  "focus_enter": "Entrar en modo de enfoque",
  "focus_exit": "Salir del modo de enfoque",
  "folder": "Carpeta",
  "folder_untitled": "Carpeta sin Título",
  "fullscreen_enter": "Ingrese a pantalla completa",
  "fullscreen_exit": "Salir de pantalla completa",
  "go_back": "Regresa <highlight>{{shortcut}}</highlight>",
  "go_forward": "Avanzar <highlight>{{shortcut}}</highlight>",
  "loading": "Cargando",
  "nav_item_actions": "$t({{type}}) Acciones…",
  "new_project": "Crear $t({{type}}) en blanco",
  "new_document": "Nuevo $t({{type}})",
  "not_found_doc": "Documento no Encontrado",
  "not_found_project": "Proyecto no Encontrado",
  "not_found_project_data": "Datos Faltantes del Proyecto",
  "insert_document": "Insertar $t({{type}})",
  "cannot_insert_document": "No se puede insertar $t({{type}}) aquí.",
  "new_file": "Archivo Nuevo",
  "new_items": "Crear…",
  "learn_more": "Aprende más…",
  "open": "Abra “{{-title}}”",
  "paste_error_incompatible_title": "Error de Pegar",
  "paste_error_incompatible_body": "$t({{type}}) no es compatible con $t({{parentType}}).",
  "privacy_policy": "Política de Privacidad",
  "project": "Proyecto",
  "projects": "Proyectos",
  "project_by": "Por",
  "project_copy": "Copiar",
  "project_settings": "Configuración del Proyecto",
  "project_rename_header": "Nombre del Proyecto",
  "project_rename_body": "Establece el nombre de su proyecto",
  "project_actions": "Acciones del Proyecto…",
  "project_duplicate": "Duplicar el Proyecto",
  "project_error_title": "No pudimos guardar eso",
  "project_error_body": "Hubo un error al guardar el último cambio que hiciste. Vuelva a cargar Dabble e inténtelo de nuevo, o comuníquese con soporte.\n\nError:\n{{- error}}",
  "project_no_permissions": "Ya no tiene permiso para modificar este proyecto, contacta a soporte",
  "project_shared_template": "Plantilla compartida",
  "project_sync_off": "Este proyecto no se sincroniza con el servidor, comuníquese con soporte si cree que se trata de un error",
  "project_rename": "Cambiar nombre de proyecto",
  "project_restore": "Restaurar proyecto",
  "project_select": "Proyecto abierto",
  "project_title_copy": "{{title}} Copiar",
  "project_untitled": "Proyecto sin Título",
  "recent_projects": "Proyectos Recientes",
  "request_feature": "Solicitar una Característica",
  "restore_from_trash": "Restaurar desde Papelera",
  "save": "Guardar",
  "saved": "Guardado",
  "saving": "Guardando…",
  "send_to_trash": "Enviar a la Papelera",
  "search_icon_tooltip": "Buscar y Reemplazar",
  "set_icon": "Establecer icono",
  "set_icon_overlay": "Establecer una superposición de iconos",
  "show_more_templates": "Mostrar plantillas",
  "hide_templates": "Ocultar plantillas",
  "start_new_project": "Iniciar un nuevo proyecto desde una plantilla",
  "still_saving": "Su proyecto se está guardando actualmente, continúe en un momento",
  "success": "Éxito",
  "suggestions": "Sugerencias",
  "support_chat": "Chatear con soporte",
  "support_email": "Soporte de Email",
  "support_visit_help": "Visita el Centro de Ayuda",
  "tagline": "Crear su Historia",
  "terms_of_service": "Términos de Servicio",
  "text_copy": "Copiar",
  "text_cut": "Cortar",
  "text_delete": "Borrar",
  "text_dictionary": "Mantenimiento de Diccionarios",
  "text_paste": "Pegar",
  "text_redo": "Rehacer",
  "text_selectall": "Seleccionar todo",
  "text_thesaurus": "Sinónimos",
  "text_undo": "Deshacer",
  "today": "Hoy dia",
  "toggle_focus": "Alternar Modo de Enfoque",
  "toggle_fullscreen": "Alternar Pantalla Completa",
  "toggle_navigation": "Alternar Navegación",
  "toggle_sidebar": "Alternar Barra Lateral",
  "trash": "Papelera",
  "trash_confirm": "¿Está seguro de que quiere enviar “{{-title}}” a la papelera?",
  "trashed_projects": "Proyectos Desechados",
  "ui_toggle_tooltip": "Alternar Características",
  "update_installed": "¡Se ha instalado una <highlight>actualización</highlight>!<br>Reiniciar ahora.",
  "update_installing": "Descargando\nactualización...",
  "update_installing_percent": "Descargando\nactualización...\n{{percent}}%",
  "copied_to_clipboard": "Copiado al portapapeles",
  "transfer_ownership": "Transferir propiedad",
  "transfer_ownership_project": "¿A qué cuenta (uid) desea transferir la propiedad del proyecto? {{project_title}}?",
  "transfer_complete": "¡La transferencia está completa!",
  "share_ownership": "Compartir Proyecto",
  "share_ownership_project": "¿Qué cuenta (uid) desea agregar como coautor del proyecto {{project_title}}?",
  "share_complete": "¡Proyecto compartido con éxito!",
  "updated_project_formatting": "Formato de proyecto actualizado con éxito",
  "alert_header_error_occured": "Ocurrió un error",
  "alert_error_occured_message": "Es posible que desee recargar/reiniciar Dabble y ponerse en contacto con el soporte si sigue ocurriendo.\n\nError:\n{{error_message}}",
  "alert_header_old_desktop": "Cliente de escritorio antiguo",
  "alert_old_desktop": "Su cliente de escritorio debe actualizarse manualmente. Vaya a https://www.dabblewriter.com/downloads en su navegador para descargar la última versión (1.6.0 o superior).",
  "alert_header_database_closed": "Base de Datos Cerrada",
  "alert_database_closed": "Su base de datos local se ha cerrado. Dabble se recargará para abrirlo de nuevo. Si esto sigue ocurriendo, póngase en contacto con soporte.",
  "alert_projet_not_fully_loaded": "Error, los datos del proyecto no se cargaron por completo",
  "alert_header_trial_error": "Error de Prueba",
  "alert_trial_error": "Hubo un error al iniciar su versión de prueba. Por favor, póngase en contacto con soporte. Realmente queremos ayudar.",
  "alert_header_email_sent": "Email Sent",
  "alert_email_sent": "Se envió un email a {{email}}",
  "alert_header_error": "Error",
  "alert_error": "",
  "alert_header_succesfully_upgraded": "Usuario Actualizado con Éxito",
  "alert_succesfully_upgraded": "¡Has actualizado el usuario a gratis!",
  "alert_header_account_merged": "Cuenta Fusionada con Éxito",
  "alert_account_merged": "Fusionaste las cuentas de usuario",
  "alert_header_user_deleted": "Usuario Eliminado con Éxito",
  "alert_user_deleted": "Borraste la cuenta de usuario",
  "confirm_header_delete_account": "Eliminar Esta cCenta",
  "confirm_delete_account": "¿Desea eliminar esta cuenta ahora?",
  "confirm_delete_user": "¿Está seguro de que quiere eliminar {{user_name}} ???",
  "confirm_header_replace": "¿Seguro?",
  "confirm_replace": "Reemplazar todo no se puede deshacer. ¿Seguro que quiere hacer esto?",
  "confirm_header_unsynced_data": "¡DETENER! Datos no Sincronizados",
  "confirm_unsynced_data": "¿Seguro que quiere cerrar sesión?\n\nTiene datos no sincronizados en algunos de sus proyectos que se perderán si cierras sesión.",
  "confirm_header_resync": "¿Re-sincronizar?",
  "confirm_resync": "¿Resincronizar después de hacer cambios en NaNoWriMo.org?",
  "confirm_header_nanowrimo_reauth": "Reautorización de NaNoWriMo",
  "confirm_nanowrimo_reauth": "Su autenticación NaNoWriMo ha caducado. ¿Desea volver a autenticarse?",
  "confirm_give_user_admin": "¿Está seguro de que quiere dar acceso a {{user_name}} a Free4Eva'?",
  "confirm_make_user_admin": "¿Está seguro de que quiere convertir a {{user_name}} en administrador con todos los privilegios de administrador?",
  "prompt_header_merge": "¿Unirse Con?",
  "prompt_merge": "¿Con qué cuenta desea fusionar ESTO?\n\nESTA SERÁ ELIMINADA",
  "inform_header_refreshed": "Renovado",
  "inform_refreshed": "Los datos de la lista de usuarios se han actualizado.",
  "inform_header_not_refreshed": "No Actualizado",
  "inform_not_refreshed": "No se pudieron actualizar los datos de la lista de usuarios.",
  "alert_header_user_upgraded": "Usuario Actualizado con Éxito",
  "alert_user_upgraded": "Hiciste a este usuario un administrador",
  "alert_header_dabble_demo": "Demostración de Dabble",
  "alert_dabble_demo": "¡Esta es solo una demostración de la aplicación Dabble!\n\n'No se proporciona soporte. La sincronización en la nube no está habilitada. El soporte sin conexión no está habilitado. 'No garantizamos la pérdida de datos. Y solo se actualiza ocasionalmente.\n\n'Úselo bajo su propio riesgo.\n\nVisite https://app.dabblewriter.com/ para registrarse en una cuenta de Dabble.\n\n¡Disfrútelo!",
  "alert_header_updated": "Actualizado",
  "alert_updated": "Ha sido actualizado. Si necesita cambiarlo, vaya a Configuración de objetivos y actualice \"Excluir recuento de palabras\".",
  "alert_header_sounds_good": "Suena Bueno",
  "alert_sounds_good": "Si cambia de opinión, puede configurarlo yendo a Configuración de objetivos y actualizando \"Excluir recuento de palabras\".",
  "alert_header_dictionary_error": "Error al Agregar al Diccionario",
  "alert_dictionary_error": "",
  "alert_header_not_available": "No Disponible",
  "alert_not_available": "El modo oscuro solo está disponible en Estándar o Premium. ¡Actualízalo para usarlo!",
  "alert_header_backfill_failed": "Relleno Fallido",
  "alert_backfill_failed_1": "No pudimos encontrar una instantánea para la versión {{version}}",
  "alert_backfill_failed_2": "Solo pudimos reabastecer a la versión {{version}}",
  "alert_header_session_timeout": "Hora de Término de la Sesión",
  "alert_session_timeout": "Su sesión ha caducado. Recargaremos la página para una experiencia óptima.",
  "home_title": "Título",
  "home_subtitle": "Subtitular",
  "home_author": "Autora",
  "home_trash_empty": "No tienes proyectos en la basura.",
  "home_templates_empty": "No tienes plantillas. ¡Puede convertir un proyecto en una plantilla desde su menú de acción!",
  "template": "Plantilla",
  "project_create": "Convertir a plantilla",
  "project_archive": "Archivo",
  "project_unarchive": "Insustroso",
  "template_edit": "Plantilla de edición",
  "archived_projects": "Proyectos archivados",
  "template_share": "Plantilla de compartir",
  "template_shared_link": "La plantilla se ha hecho pública y un enlace compartido copiado en su portapapeles.",
  "template_not_sharable": "El proyecto compartido no es una plantilla válida.",
  "template_shared": "<strong>{{templater}}</strong> ha compartido <strong>{{title}}</strong> contigo.",
  "template_accept": "Estás iniciado sesión con <strong>{{email}}</strong>. ¿Desea aceptar esta plantilla compartida con usted?",
  "template_signin_first_message": "Inicie sesión para aceptar esta invitación de plantilla.",
  "template_view": "Ver plantilla",
  "template_actions": "Acciones de plantilla…",
  "template_new": "Crear $t({{type}}) de $t({{name}})",
  "template_rename": "Cambiar el nombre de la plantilla",
  "template_settings": "Configuración de plantilla",
  "template_count": "Número de usuarios que usan esta plantilla",
  "template_confirm": "¿Estás seguro de que quieres que este proyecto sea una plantilla?",
  "dabble_shortcuts": "Dabble Atajos",
  "nav_delete": "Eliminar el elemento de navegación seleccionado",
  "doc_heading_1": "Formato a un encabezado 1",
  "doc_heading_2": "Formato a un encabezado 2",
  "doc_heading_3": "Formato a un encabezado 3",
  "doc_heading_4": "Formato a un encabezado 4",
  "doc_heading_5": "Formato a un encabezado 5",
  "doc_heading_6": "Formato a un encabezado 6",
  "doc_bold": "Formato en negrita",
  "doc_italic": "Formato cursiva",
  "doc_copy": "Selección de copias",
  "doc_cut": "Selección de corte",
  "doc_paste": "Selección de pasta",
  "doc_undo": "Deshacer el último cambio",
  "doc_redo": "Rehacer Último cambio",
  "doc_find": "Abierto Encontrar Herramienta",
  "doc_replace": "Abrir herramienta Buscar y reemplazar",
  "doc_new_scene": "Crear una nueva escena tras cursor",
  "doc_new_chapter": "Crear un nuevo capítulo tras cursor",
  "doc_current_mac": "Ir a la selección actual (mac solamente)",
  "doc_next_mac": "Ir a la próxima selección (mac solamente)",
  "navigation_pane": "Panel de navegación",
  "formatting": "Formato",
  "dabble_document": "Dabble Documento",
  "find": "Encontrar",
  "replace": "Reemplazar",
  "find_replace": "Buscar y reemplazar",
  "find_in": "Encontrar en",
  "find_in_project": "Encontrar en el proyecto",
  "match_word": "Compare la palabra completa",
  "match_case": "Estuche",
  "use_regex": "Use expresiones regulares",
  "replace_once": "una",
  "replace_all": "todos",
  "shortcuts": "Atajos",
  "add_new": "Agregar nueva",
  "close": "Cerca",
  "templates": "Plantillas",
  "templates_add_from": "Agregar de plantilla",

  "speechify_prompt_title": "Extensión Speechify instalada",
  "speechify_prompt_message": "Notamos que usted ha Speechify instalado. Se sabe que esta extensión causa algunos problemas, sobre todo las palabras repetitivas mientras se escribe o se agrega al azar. Si experimenta estos problemas, intente reinstalar la extensión, y si el problema persiste, apague la extensión.Estamos evaluando otras soluciones."
}
