import { settingsStore } from '@dabble/data/settings';
import SyncAppHeader from './components/SyncAppHeader.svelte';
import SyncStatus from './components/SyncStatus.svelte';
import UpdateRequired from './components/UpdateRequired.svelte';
import './locales';

settingsStore.configure('dabble', {
  headerRight: { SyncAppHeader },
  statusBarLeft: { SyncStatus },
  globals: { UpdateRequired },
  plugins: {
    account: {
      // preferences: { DataManagement, AppCache },
    },
  },
});
