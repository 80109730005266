import { saveAs } from 'file-saver';
import { Delta } from 'typewriter-editor';
import {
  ExportData,
  ExportFile,
  ExportFileConstructor,
  Footer,
  Header,
  ParagraphOptions,
  Section,
  Style,
  Styles,
} from '../types';

interface Context {
  content: string;
}

const BULLETS = {
  checked: '☑',
  check: '☐',
  dash: '—',
  default: '•',
};

class TextFile implements ExportFile {
  styles = new TextStyles();
  content = '';
  previousLineAQuote = false;

  constructor(public data: ExportData) {}

  setPageSize(width: number, height: number): this {
    return this;
  }

  pageNumbersStart(start: number): this {
    return this;
  }

  addSection(): Section {
    return new NoopSection();
  }

  addParagraph(options: ParagraphOptions, content: Delta | string): this {
    if (typeof content !== 'string') {
      content = content.ops.map(op => (typeof op.insert === 'string' ? op.insert : op.insert.br ? '\n' : '')).join('');
    }
    if (options.image) {
      return this;
    } else if (options.hr) {
      content = '\n' + '—'.repeat(50) + '\n';
    } else if (options.list) {
      const prefix = '\t'.padEnd(options.list.indent || 0, '\t');
      if (options.list.kind === 'ordered') {
        const start = options.list.start || getListStart(this.content, options.list.type || '1');
        content = `${start}. ${content}`;
      } else {
        const char =
          options.list.type === 'check' && options.list.checked
            ? BULLETS.checked
            : BULLETS[options.list.type || 'default'];
        content = char + ' ' + content;
      }
      content = prefix + content.replace(/\n/g, '\n' + prefix);
    } else if (options.style?.includes('Quote')) {
      content = '\t' + content.replace(/\n/g, '\n\t');
    } else if (options.style?.startsWith('Heading')) {
      content = content.toUpperCase() + '\n';
    }
    this.content += content + '\n';
    return this;
  }

  addEmptyLines(count: number): this {
    this.content += ''.padEnd(count, '\n');
    return this;
  }

  addPageBreak(): this {
    return this.addEmptyLines(5);
  }

  async save(): Promise<any> {
    const blob = new Blob([this.content], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${this.data.filename}.txt`);
  }
}

export class NoopSection implements Section {
  addHeader(): Header {
    return new NoopHeaderFooter();
  }
  addFooter(): Footer {
    return new NoopHeaderFooter();
  }
}

class NoopHeaderFooter implements Header, Footer {
  addParagraph(options: ParagraphOptions, content: Delta | string): this {
    return this;
  }
}

// No-op styles for text files
class TextStyles implements Styles {
  style(name: string, basedOn?: string): Style {
    return style;
  }
}

class TextStyle implements Style {
  defaultNext(value: string): this {
    return this;
  }

  alignment(value: 'start' | 'center' | 'end' | 'justified'): this {
    return this;
  }

  indent(value: number, side?: 'start' | 'end' | 'firstLine'): this {
    return this;
  }

  keepLinesTogether(value?: boolean): this {
    return this;
  }

  keepWithNext(value?: boolean): this {
    return this;
  }

  lineSpacing(value: number): this {
    return this;
  }

  spaceAbove(value: number): this {
    return this;
  }

  spaceBelow(value: number): this {
    return this;
  }

  tabStop(value: number, alignment?: 'start' | 'end'): this {
    return this;
  }

  backgroundColor(value: string | null): this {
    return this;
  }

  bold(value?: boolean): this {
    return this;
  }

  color(value: string): this {
    return this;
  }

  fontFamily(value: string, weight?: number): this {
    return this;
  }

  fontSize(value: number): this {
    return this;
  }

  italic(value?: boolean): this {
    return this;
  }

  smallCaps(value?: boolean): this {
    return this;
  }

  strikeThrough(value?: boolean): this {
    return this;
  }

  underline(value?: boolean): this {
    return this;
  }
}

const style = new TextStyle();

function getListStart(content: string, type: string) {
  // Figure out where we are at in the list
  const lines = content.split('\n');
  let last = '';
  for (let i = lines.length - 1; i >= 0; i--) {
    const match = lines[i].match(/^(\t+)(\w+)\./);
    if (!match) break;
    const [, prefix, lastItem] = match;
    if (prefix.length === this.prefix.length) {
      if (isSameList(lastItem, type)) {
        last = lastItem;
      }
      break;
    }
  }
  return incrementListItem(last, type);
}

function isSameList(number1: string, type: string) {
  if (type === '1') return number1.match(/^\d+$/);
  if (type === 'a' || type === 'A') return number1.match(/^[a-zA-Z]+$/);
  if (type === 'I') return number1.match(/^[IVXLCDM]+$/);
  if (type === 'i') return number1.match(/^[ivxlcdm]+$/);
}

function incrementListItem(number: string, type: string) {
  if (!number) return type;
  if (type === '1') return '' + (parseInt(number) + 1);
  if (type === 'a' || type === 'A') return String.fromCharCode(number.charCodeAt(0) + 1);
  if (type === 'I') return incrementRoman(number);
  if (type === 'i') return incrementRoman(number.toUpperCase()).toLowerCase();
}

function incrementRoman(numeral: string): string {
  const numerals: Record<string, number> = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
  let value = 0;
  for (let i = 0; i < numeral.length; i++) {
    if (i > 0 && numerals[numeral[i]] > numerals[numeral[i - 1]]) {
      value += numerals[numeral[i]] - 2 * numerals[numeral[i - 1]];
    } else {
      value += numerals[numeral[i]];
    }
  }
  value++;
  const thousands = Math.floor(value / 1000);
  const hundreds = Math.floor((value % 1000) / 100);
  const tens = Math.floor((value % 100) / 10);
  const ones = value % 10;
  return (
    'M'.repeat(thousands) +
    (hundreds === 9
      ? 'CM'
      : hundreds >= 5
      ? 'D' + 'C'.repeat(hundreds - 5)
      : hundreds === 4
      ? 'CD'
      : 'C'.repeat(hundreds)) +
    (tens === 9 ? 'XC' : tens >= 5 ? 'L' + 'X'.repeat(tens - 5) : tens === 4 ? 'XL' : 'X'.repeat(tens)) +
    (ones === 9 ? 'IX' : ones >= 5 ? 'V' + 'I'.repeat(ones - 5) : ones === 4 ? 'IV' : 'I'.repeat(ones))
  );
}

export const File: ExportFileConstructor = TextFile;
