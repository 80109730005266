{
  "font_general": "General",
  "font_romance": "Romance",
  "font_thriller": "Suspenso",
  "font_literary": "Literario",
  "font": "Font",
  "font_sans": "Sans Serif",
  "font_size_default": "(Predeterminado)",
  "font_save": "Guardar Formato",
  "font_reset": "Restablecer todos los Valores Predeterminados",
  "font_dyslexic": "Disléxico",
  "font_double_spaced": "Doble Espacio",
  "font_single_spaced": "Espaciado Único",
  "font_half_spaced": "1 1/2 Espacio",
  "font_style_book": "Estilo de Libro",
  "font_style_book_modified": "Estilo de Libro Modificado",
  "font_style_modern": "Estilo Moderno",
  "font_times": "Times New Roman",
  "font_courier": "Courier",
  "font_comic": "Comic Sans",
  "font_screenplay": "Screenplay",
  "font_screenplay_sans": "Screenplay Sans Serif",
  "novel": "Novela",
  "novel_book": "Libro",
  "novel_book_scenes": "Escenas",
  "novel_book_scenes_count": "({{count}})",
  "novel_book_untitled": "Libro sin Título",
  "novel_prologue_untitled": "Prólogo sin Título",
  "novel_book_unsubtitled": "Subtítulo",
  "novel_book_word_count": "{{wordCount}} palabra",
  "novel_book_word_count_other": "{{wordCount}} palabras",
  "novel_doc_description": "{{type}} Notas",
  "novel_doc_notes": "Notas",
  "novel_plot_notes": "Notas de la Trama",
  "novel_formatting": "Formateo",
  "novel_font": "Formateo",
  "novel_merge_scene": "Fusionar Escena…",
  "novel_scene_added": "Escena Añadida",
  "novel_scene_added_body": "Agregaste con éxito una escena a este capítulo.",
  "novel_scene_split": "División de Escena",
  "novel_scene_split_body": "Dividiste con éxito tu escena.",
  "novel_scene_preview": "Vista Previa de la Escena",
  "novel_scene_style": "Estilo de Párrafo de Escena",
  "novel_scene_spacing": "Espaciado de Línea de Escena",
  "novel_note_preview": "Vista Previa de la Nota",
  "novel_note_style": "Estilo de Párrafo de Nota",
  "novel_note_spacing": "Espaciado Entre Líneas de Nota",
  "novel_cast": "Reparto",
  "novel_cast_untitled": "Reparto sin Título",
  "novel_chapter": "Capítulo",
  "novel_image_delete": "¿Eliminar Imagen?",
  "novel_image_delete_message": "Esto eliminará permanentemente esta imagen. ¿Te gustaria continuar?",
  "novel_image_unsupported": "Tipo de Archivo no Soportado",
  "novel_image_unsupported_message": "Ingrese una sola imagen con la extensión .png, .jpg, jpeg, .gif, .webp o .tiff",
  "novel_title_image": "Establecer Imagen de Título",
  "novel_title_image_remove": "Eliminar Imagen de Título",
  "novel_image_drop_dialog_main_text": "Decore el título de su capítulo o parte con una ilustración, fotografía u otra imagen.",
  "novel_image_drop_dialog_sub_text": "",
  "novel_image_drop_text": "Arrastra tu imagen de página completa aquí.",
  "novel_image_drop_sub_text": "(Relación de aspecto 1:1,5 o 1:1,6)",
  "novel_cover_art": "Establecer Arte de Cubierta",
  "novel_cover_art_remove_dialog_title": "¿Quitar la Cubierta?",
  "novel_cover_art_remove_dialog_text": "Esto eliminará su cubierta por completo. También puede actualizar la imagen arrastrando una nueva sobre la anterior.",
  "novel_cover_art_remove": "Eliminar Cubierta",
  "novel_cover_art_drop_main_text": "Una imagen de cubierta es una excelente manera de destacar su novela y aparecerá en la pantalla de inicio y se compartirá en la web.",
  "novel_cover_art_drop_sub_text": "Una buena imagen de cubierta debe tener 816 px por 1056 px",
  "novel_modal_drop_drag_text": "Arrastra tu imagen de cubierta aquí",
  "novel_modal_drop_or_divider": "O",
  "novel_modal_drop_browse_button": "Búsqueda de Archivos",
  "novel_chapter_split": "División del Capítulo",
  "novel_chapter_split_body": "Dividiste con éxito tu capítulo.",
  "novel_chapter_split_here": "Dividir capítulo aquí",
  "novel_chapter_number": "Capítulo {{number}}",
  "novel_chapter_numbering_off": "Convertir de Capítulo",
  "novel_chapter_numbering_off_confirm": "¿Dejar de ser un capítulo?\n\nEliminar el título para volver a convertirlo en un capítulo.",
  "novel_convert_prologue_to_chapter": "¿Convertir prólogo en un capítulo?",
  "novel_character": "Personaje",
  "novel_characters": "Caracteres",
  "novel_main_characters": "Personajes principales",
  "novel_secondary_characters": "Personajes secundarios",
  "novel_world_building": "Construcción del Mundo",
  "novel_character_untitled": "Personaje sin Nombre",
  "novel_delete_column": "Eliminar esta columna",
  "novel_delete_column_confirm": "¿Está seguro de que desea eliminar esta columna y todos sus puntos?",
  "novel_delete_row": "Eliminar esta fila",
  "novel_delete_row_confirm": "¿Está seguro de que desea eliminar esta fila con todos sus puntos?",
  "novel_delete_row_with_scene_confirm": "¿Está seguro de que desea eliminar todos los puntos de esta fila?\n\nTendrá que eliminar la escena manualmente antes de poder eliminar la fila.",
  "novel_delete_row_with_scene_only": "Debe eliminar la escena manualmente antes de poder eliminar la fila.\n\nAl eliminar la escena aquí, también se eliminará del manuscrito.",
  "novel_delete_row_with_scene_only_title": "No se Puede Eliminar Todavía",
  "novel_delete_scene_confirm": "Eliminar una escena de la trama la eliminará del libro.\n\n¿Estás seguro de que quieres eliminar \"{{-title}}\"?",
  "novel_doc_descriptions": "Notas",
  "novel_page": "Nota",
  "novel_page_untitled": "Nota sin Título",
  "novel_folder": "Carpeta",
  "novel_image": "Imagen",
  "novel_insert_row": "Insertar fila",
  "novel_manuscript": "Manuscrito",
  "novel_notes": "Notas de la Historia",
  "novel_part": "Parte",
  "novel_part_number": "Parte {{number}}",
  "novel_plot": "Trama Cuadrícula",
  "novel_plot_incorrect_title": "Error de Trama Cuadrícula",
  "novel_plot_incorrect_body": "Se ha producido un error al actualizar la cuadrícula de gráficos en segundo plano para mantener la sincronización con este cambio que se acaba de realizar.\n\nRevirtiendo el cambio.\n\nPóngase en contacto con el servicio de asistencia para resolver el problema.",
  "novel_plot_for": "Trama por {{-bookTitle}}",
  "novel_plot_untitled": "Trama Cuadrícula sin Título",
  "novel_plots": "Trama",
  "novel_plot_line": "Linea de Trama",
  "novel_plot_line_untitled": "Linea de trama sin Título",
  "novel_plot_point": "Punto de Trama",
  "novel_plot_point_untitled": "Punto de Trama sin Título",
  "novel_scene": "Escena",
  "novel_scene_body_empty": "Entonces…",
  "novel_scene_body_first": "Érase una vez…",
  "novel_scene_description_empty": "Una descripción de lo que sucede en tu escena…",
  "novel_scene_notes": "Notas de Escena",
  "novel_scene_description": "Descripción de la Escena",
  "novel_scene_split_here": "Escena dividida aquí",
  "novel_scene_title_empty": "Escena sin Título",
  "novel_scenes": "Escenas",
  "novel_scenes_for": "Escenas por “{{-for}}”",
  "novel_scenes_merge_which": "¿En qué dirección quieres fusionar la escena?",
  "novel_scenes_merge_above": "Arriba",
  "novel_scenes_merge_below": "Abajo",
  "novel_title": "Título",
  "novel_author": "Autor",
  "novel_title_page": "Libro",
  "novel_untitled": "Novela sin Título",
  "read_to_me": "Leerme",
  "add_title_image": "Agregar Imagen de Título",
  "project_insert_hr": "Insertar Regla Horizontal",
  "project_insert_dl": "Insertar la lista de propiedades",
  "project_insert_dlh": "Insertar encabezado de la lista de propiedades",
  "export_to_web": "Compartir en la Web",
  "share_to_web_header": "Compartir en la Web",
  "share_to_web_documents": "Documentos Compartidos",
  "shared_title": "Título",
  "shared_created": "Creado en",
  "shared_expires": "Caduca",
  "shared_type": "Tipo",
  "shared_novel_book": "Libro",
  "shared_novel_part": "Parte",
  "shared_novel_chapter": "Capítulo",
  "shared_novel_scene": "Escena",
  "shared_set_expiration": "Establecer Fecha de Caducidad",
  "shared_delete": "Eliminar",
  "shared_view": "Vista",
  "shared_send_email": "Enviado por Email",
  "shared_modal_text": "<p>El documento seleccionado se ha guardado en la web. Puede administrar el acceso a este y todos los demás documentos compartidos en Configuración del proyecto.<p><p><strong>Cualquier persona con este enlace puede ver el documento.</strong></p>",
  "shared_email_header": "Enviar documento compartido por Email",
  "shared_email_text": "Enviar email a las siguientes direcciones:",
  "shared_add_email": "Dirección de Email",
  "shared_add": "Agregar",
  "shared_no_email": "Sin direcciones de email",
  "shared_email_message": "Mensaje personalizado que le gustaría enviar",
  "shared_email_sent": "Compartido con las direcciones de email proporcionadas.",
  "request_share_to_web": "Creando tu enlace para compartir",
  "add_ribbon": "Agregar Cinta",
  "edit_ribbon": "Editar Cinta",
  "remove_ribbon": "Retire la Cinta",
  "plot_grid_header": "Tramas",
  "plot_grid_create_generic": "Cree una cuadrícula de trama genérica:",
  "plot_grid_create_new": "Crear una Nueva Cuadrícula de Trama",
  "plot_grid_create_for_book": "Crea una cuadrícula de trama para uno de sus libros:",
  "plot_grid_introducing": "Introducción a las Tramas",
  "plot_grid_explanation": "<p>En Dabble, una <strong>cuadrícula de tramas  </strong> se compone de <strong>líneas de tramas  </strong> y <strong>puntos de tramas  </strong>.</p>",
  "plot_grid_grid": "Trama Cuadrícula",
  "plot_grid_a_plot_grid": "Una cuadrícula de trama:",
  "plot_grid_bullet_points": "<li>ofrece una descripción general de varias líneas de la trama</li><li>alinea los puntos de la trama entre sí</li><li>adjunta los puntos de la trama a las escenas</li>",
  "plot_grid_plot_line": "Línea de Trama",
  "plot_grid_plot_line_explanation": "<p>Una Línea de Trama se enfoca en una sola subtrama.</p><p>Cada Punto de Trama en una Línea de Trama está representado por una ficha con un título y una descripción. Puede completar tantos detalles sobre cada uno como necesite.</p>",
  "plot_grid_plot_point": "Punto de Trama",
  "plot_grid_plot_grid_plot_point_explanation": "<p>Los Puntos de Trama representan un solo evento dentro de su Líneas de la Trama.</p><p>Los Puntos de Trama se pueden adjuntar a escenas alineándolos en Plot Grids. Una vez hecho esto, los verá en la columna de la derecha mientras trabaja en su escena, lo que le permitirá realizar un seguimiento de lo que debe suceder en esa escena.</p>"
}
