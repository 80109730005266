<script lang="ts">
  import { readonlyStore } from '@dabble/data/app-state';
  import { uidStore } from '@dabble/data/ids';
  import { t } from '@dabble/data/intl';
  import { projectMetasStore, projectsRolesStore } from '@dabble/data/project-data';
  import { confirm } from '@dabble/data/ui';
  import { userProjectsStore } from '@dabble/data/user-data';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import {
    mdiArchive,
    mdiContentDuplicate,
    mdiDelete,
    mdiPen,
    mdiPencil,
    mdiShare,
    mdiTextBoxMultiple,
    mdiUndoVariant,
  } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';

  export let id: string;

  let dispatch = createEventDispatcher();
  $: uid = $uidStore;
  $: projectMeta = $projectMetasStore[id];
  $: userProject = $userProjectsStore[id];
  $: rolesStore = $projectsRolesStore[id];
  $: isTemplate = projectMeta.isTemplate;
  $: canEditTemplate = $uidStore && $rolesStore.users[uid] && isTemplate;
  $: hasAccess = !!$rolesStore.users[uid];

  async function makeTemplate() {
    if (await confirm($t('project_create'), $t('template_confirm'), { yesNo: true })) {
      dispatch('template');
    }
  }
</script>

{#if hasAccess}
  <button class="dropdown-item" on:click={() => dispatch('rename')} disabled={$readonlyStore}>
    <Icon path={mdiPencil} />
    {$t(isTemplate ? 'template_rename' : 'project_rename')}
  </button>
{/if}
{#if !isTemplate}
  <button class="dropdown-item" on:click={() => dispatch('duplicate')} disabled={$readonlyStore}>
    <Icon path={mdiContentDuplicate} />
    {$t('project_duplicate')}
  </button>
  <button class="dropdown-item" on:click={makeTemplate} disabled={$readonlyStore}>
    <Icon path={mdiTextBoxMultiple} />
    {$t('project_create')}
  </button>
{/if}
{#if isTemplate}
  {#if !canEditTemplate}
    <button class="dropdown-item" on:click={() => dispatch('view')} disabled={$readonlyStore}>
      <Icon path={mdiTextBoxMultiple} />
      {$t('template_view')}
    </button>
  {:else}
    <button class="dropdown-item" on:click={() => dispatch('edit')} disabled={$readonlyStore}>
      <Icon path={mdiPen} />
      {$t('template_edit')}
    </button>
  {/if}
  <button class="dropdown-item" on:click={() => dispatch('share')} disabled={$readonlyStore}>
    <Icon path={mdiShare} />
    {$t('template_share')}
  </button>
{/if}
{#if userProject.category === 'archive'}
  <button class="dropdown-item" on:click={() => dispatch('category', '')} disabled={$readonlyStore}>
    <Icon path={mdiArchive} />
    {$t('project_unarchive')}
  </button>
{:else}
  <button class="dropdown-item" on:click={() => dispatch('category', 'archive')} disabled={$readonlyStore}>
    <Icon path={mdiArchive} />
    {$t('project_archive')}
  </button>
{/if}
{#if userProject.category === 'trash'}
  <button class="dropdown-item" on:click={() => dispatch('restore')} disabled={$readonlyStore}>
    <Icon path={mdiUndoVariant} />
    {$t('restore_from_trash')}
  </button>
  <hr />
{/if}
<button class="dropdown-item" on:click={() => dispatch('delete')} disabled={$readonlyStore}>
  <Icon path={mdiDelete} />
  {userProject.category === 'trash' ? $t('delete_permanently') : $t('send_to_trash')}
</button>
