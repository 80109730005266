<script lang="ts" context="module">
  import { t } from '@dabble/data/intl';

  export type InformType = 'success' | 'info' | 'warning' | 'danger';
  export type AlertOptions = {
    noFocus?: boolean;
    okButtonText?: string;
    fireworks?: boolean;
  };
  export type ConfirmOptions = {
    noFocus?: boolean;
    okButtonText?: string;
    yesNo?: boolean;
    danger?: boolean;
  };
  export type PromptOptions = {
    noFocus?: boolean;
    placeholder?: string;
  };
  interface PopupData<Opt = never, ResolveType = void> {
    title: string;
    message?: string;
    options?: Opt;
    resolve: (value?: ResolveType) => void;
  }
  interface InformData extends PopupData {
    type: InformType;
    hovered?: boolean;
    timeout: any;
    resolveIfTimedout: () => void;
  }
  interface AlertData extends PopupData<AlertOptions> {}
  interface ConfirmData extends PopupData<ConfirmOptions, boolean | null> {}
  interface PromptData extends PopupData<PromptOptions, string> {
    input: string;
  }
  let informData = writable<InformData>(null);
  let alertData = writable<AlertData>(null);
  let confirmData = writable<ConfirmData>(null);
  let promptData = writable<PromptData>(null);

  export async function inform(type: InformType, title: string, message?: string): Promise<void> {
    if (informData.get()) {
      clearTimeout(informData.get().timeout);
      informData = null;
    }

    if (!message) {
      message = title;
      title = '';
    }

    await new Promise<void>(resolve => {
      let timeout = setTimeout(() => {
        timeout = null;
        if (!informData.get().hovered) resolve();
      }, 6000);

      informData.set({
        type,
        title,
        message,
        resolve,
        timeout,
        resolveIfTimedout: () => {
          if (!timeout) {
            informData.get().hovered = false;
            resolve();
          }
        },
      });
    });
    informData.set(null);
  }

  export async function alert(title: string, message?: string, options?: AlertOptions): Promise<void> {
    await new Promise(resolve => {
      alertData.set({ title, message, resolve, options });
    }).then(() => {
      alertData.set(null);
    });
  }

  export function confirm(title: string, options?: ConfirmOptions): Promise<boolean>;
  export function confirm(title: string, message?: string, options?: ConfirmOptions): Promise<boolean>;
  export async function confirm(
    title: string,
    message?: string | ConfirmOptions,
    options?: ConfirmOptions
  ): Promise<boolean> {
    if (typeof message !== 'string') {
      options = message || options;
      message = title;
      title = t.get()('confirm');
    }

    const answer = await new Promise<boolean>(resolve => {
      confirmData.set({
        title,
        message,
        options,
        resolve,
      });
    });
    confirmData.set(null);
    return answer;
  }

  export async function prompt(title: string, message: string, options?: PromptOptions): Promise<string> {
    const answer = await new Promise<string>(resolve => {
      promptData.set({ title, message, options, input: '', resolve });
    });
    promptData.set(null);
    return answer;
  }
</script>

<script lang="ts">
  import { enterKey } from '@dabble/toolkit/events';
  import { focus } from '@dabble/toolkit/focus';
  import { autolink, br, escape } from '@dabble/toolkit/helpers';
  import { mdiAlertOctagon } from '@mdi/js';
  import { writable } from 'easy-signal';
  import Alert from './Alert.svelte';
  import Fireworks from './Fireworks.svelte';
  import Icon from './Icon.svelte';
  import Modal from './Modal.svelte';

  let hovered = false;
  $: if ($informData) {
    $informData.hovered = hovered;
  } else {
    hovered = false;
  }
</script>

{#if $informData}
  <Alert
    type={$informData.type}
    dismissible
    global
    bind:hovered
    on:close={() => $informData.resolve()}
    on:unhovered={() => $informData.resolveIfTimedout()}
  >
    {#if $informData}
      {#if $informData.title}<strong>{$informData.title}</strong>{/if}
      {$informData.message}
    {/if}
  </Alert>
{/if}

{#if $alertData}
  <Modal
    title={$alertData.title}
    size="small"
    on:close={() => $alertData.resolve()}
    nofocus={$alertData.options.noFocus}
  >
    <section>
      {#if $alertData.options.fireworks}
        <Fireworks />
      {/if}
      <p>{@html autolink(br(escape($alertData.message)), true)}</p>
    </section>
    <footer>
      <button class="btn primary" on:click={() => $alertData.resolve()} use:focus
        >{$alertData.options.okButtonText || $t('dialog_ok')}</button
      >
    </footer>
  </Modal>
{/if}

{#if $confirmData}
  <Modal
    title={$confirmData.title}
    size="small"
    on:close={() => $confirmData.resolve(null)}
    nofocus={$confirmData.options.noFocus}
    class={$confirmData.options.danger ? 'danger' : undefined}
  >
    <section class:danger={$confirmData.options.danger}>
      {#if $confirmData.options.danger}
        <div class="danger">
          <Icon path={mdiAlertOctagon} />
        </div>
      {/if}
      <p>{@html autolink(br(escape($confirmData.message)), true)}</p>
    </section>
    <footer>
      <button class="btn secondary" on:click={() => $confirmData.resolve(false)}
        >{$confirmData.options.yesNo ? $t('dialog_no') : $t('dialog_cancel')}</button
      >
      <button class="btn primary" on:click={() => $confirmData.resolve(true)} use:focus
        >{$confirmData.options.yesNo ? $t('dialog_yes') : $confirmData.options.okButtonText || $t('dialog_ok')}</button
      >
    </footer>
  </Modal>
{/if}

{#if $promptData}
  <Modal
    title={$promptData.title}
    size="small"
    on:close={() => $promptData.resolve('')}
    nofocus={$promptData.options.noFocus}
  >
    <section>
      <p>{@html autolink(br(escape($promptData.message)), true)}</p>
      <input
        type="text"
        class="form-control"
        placeholder={$promptData.options.placeholder || ''}
        bind:value={$promptData.input}
        use:enterKey={() => $promptData.resolve($promptData.input)}
        use:focus
      />
    </section>
    <footer>
      <button class="btn secondary" on:click={() => $promptData.resolve('')}>{$t('dialog_cancel')}</button>
      <button class="btn primary" on:click={() => $promptData.resolve($promptData.input)}>{$t('dialog_ok')}</button>
    </footer>
  </Modal>
{/if}

<style>
  section.danger {
    display: flex;
  }
  section .danger {
    color: #d9534f;
    font-size: 72px;
    line-height: 72px;
    padding: 10px 10px 10px 0;
  }
</style>
