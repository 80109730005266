import { settingsStore } from '@dabble/data/settings';
import { mdiAccountBox, mdiAccountBoxMultiple } from '@mdi/js';

settingsStore.configure('novel_cast', {
  hasChildren: true,
  openByDefault: true,
  icon: mdiAccountBoxMultiple,
  hideWordCount: true,
  placeholders: {
    title: 'novel_cast_untitled',
  },
  menuTypes: ['novel_character'],
  validChildTypes: {
    novel_character: true,
  },
  requiresFeature: 'story_notes',
  convertToTemplate: true,
  findSettings: {
    childrenShouldBeSearched: true,
  },
});

settingsStore.configure('novel_character', {
  hasChildren: true,
  hoverFolderToggle: true,
  icon: mdiAccountBox,
  placeholders: {
    title: 'novel_character_untitled',
  },
  mentionsMenu: true,
  validChildTypes: {
    novel_page: true,
    note_image: true,
  },
  requiresFeature: 'story_notes',
  findSettings: {
    childrenShouldBeSearched: true,
  },
});
