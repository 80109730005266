<script lang="ts">
  import { agreeable, agreeableState } from '@dabble/data/agreeable';
  import { t } from '@dabble/data/intl';
  import { projectMetaStore, projectStore } from '@dabble/data/project-data';
  import { ERROR, PENDING, SAVED, SAVING, SaveStatus } from '@dabble/data/stores/project';
  import { Project } from '@dabble/data/types';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiContentSaveEdit } from '@dabble/toolkit/custom-icons';
  import { br, escape } from '@dabble/toolkit/helpers';
  import { tooltipBottom } from '@dabble/toolkit/tooltip';
  import {
    mdiClose,
    mdiCloud,
    mdiCloudAlert,
    mdiCloudArrowUp,
    mdiCloudCheck,
    mdiCloudOff,
    mdiCloudSync,
    mdiContentSaveAlert,
    mdiContentSaveCheck,
  } from '@mdi/js';
  import { AgreeableState } from 'agreeable-client/dist/types';

  export let showOne = false;
  let showErrorMessage = false;

  const saveStatus = projectStore.status;

  $: localPath = getIconPath($saveStatus);
  $: cloudIcon = getCloudIcon($agreeableState, $projectStore.project);
  $: localMessage = getLocalMessage($saveStatus);
  $: cloudMessage = getCloudMessage($agreeableState, $projectStore.project);
  $: hasError = $saveStatus === 'error' || $agreeableState.syncing instanceof Error;
  $: showErrorMessage = hasError;

  function getIconPath(saveStatus: SaveStatus) {
    if (saveStatus === ERROR) return mdiContentSaveAlert;
    if (saveStatus === PENDING) return mdiContentSaveEdit;
    if (saveStatus === SAVING) return mdiContentSaveEdit;
    if (saveStatus === SAVED) return mdiContentSaveCheck;
  }

  function getCloudIcon(state: AgreeableState, project: Project) {
    const unsynced = project && projectMetaStore.hasLocalChanges.get();
    if (state.authed) {
      if (state.syncing instanceof Error) return mdiCloudAlert;
      if (state.syncing) return mdiCloudSync;
      if (unsynced) return mdiCloudArrowUp;
      return mdiCloudCheck;
    } else {
      return mdiCloudOff;
    }
  }

  function getLocalMessage(saveStatus: SaveStatus) {
    return $t('save_' + saveStatus);
  }

  function getCloudMessage(state: AgreeableState, project: Project) {
    const unsynced = project && projectMetaStore.hasLocalChanges.get();
    if (state.authed) {
      if (state.syncing instanceof Error) return $t('syncing_error', { error: state.syncing.message });
      if (unsynced) return $t('syncing_off');
      if (state.syncing) return $t('syncing_syncing');
      return $t('syncing_complete');
    } else {
      return $t('syncing_offline');
    }
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- TODO remove the on:click or give it a special keyboard shortcut for devs to test with -->
<div
  class="sync-status"
  on:click={() => (agreeable.state.get().connected ? agreeable.disconnect() : agreeable.connect())}
>
  {#if !showOne || !$agreeableState.authed}
    <div class="status-icon" use:tooltipBottom={localMessage}>
      <Icon path={localPath} />
    </div>
  {/if}
  {#if !showOne}
    <div class="separator" />
  {/if}
  {#if !showOne || $agreeableState.authed}
    <div class="status-icon" use:tooltipBottom={cloudMessage}>
      <Icon
        path={cloudIcon === mdiCloudSync ? mdiCloud : cloudIcon}
        overlay={cloudIcon === mdiCloudSync ? 'cloudSync' : undefined}
        spin={cloudIcon === mdiCloudSync}
      />
    </div>
  {/if}
  {#if showErrorMessage}
    <Dropdown placement="top-start" arrow offset="0 8" noFocus={true}>
      <div class="error-box">
        <button class="icon close-btn" on:click={() => (showErrorMessage = false)}><Icon path={mdiClose} /></button>
        <h6>{$t('error')}</h6>
        <section>
          {@html br(
            escape(
              $t($agreeableState.syncing instanceof Error ? 'syncing_error_message' : 'save_error_message', {
                error: $agreeableState.syncing instanceof Error && $agreeableState.syncing.message,
              })
            )
          )}
        </section>
      </div>
    </Dropdown>
  {/if}
</div>

<style>
  .sync-status {
    color: var(--text-color-lighter);
    display: flex;
    align-items: center;
    padding: 0 4px;
    cursor: default;
  }
  .status-icon {
    display: grid;
    place-content: center;
    padding: 0.2em;
  }
  .separator {
    margin: 0 0.25rem;
    width: 1px;
    height: 20px;
    box-shadow: var(--vertical-bevel);
  }
  .error-box {
    position: relative;
    padding: 16px 12px;
    max-width: 300px;
    color: var(--text-color-light);
  }
  .icon.close-btn {
    position: absolute;
    right: 0;
    top: 4px;
    line-height: 1;
  }
  .error-box h6 {
    margin: 0 0 8px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: var(--font-size-xs);
  }
  .error-box section {
    font-size: var(--font-size-sm);
  }
</style>
