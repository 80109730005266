import { plugins } from '@dabble/data/plugins';
import { locallyStoredWritable } from '@dabble/data/stores/locally-stored-writable';
import { User } from '@dabble/data/types';
import { getUser } from '@dabble/data/users-data';
import { Editor } from 'typewriter-editor';

export interface UserPublicData extends User {
  uid: string;
  photoUrl: string;
}

type UserGetter = (uid: string) => UserPublicData;

export interface UsersMap {
  [uid: string]: UserPublicData;
}

export const showCoauthorsStore = locallyStoredWritable('showCoauthors', false);
const emptyInfo = { uid: '', name: '', email: '', photoUrl: '' };

plugins.register({ showCoauthors: showCoauthorsStore });

const cache = new Map<string, User>();

// needs to be a synchronous function, returning a placeholder until the data is loaded
export function getUserSync(uid: string, editor: Editor): User {
  if (cache.has(uid)) return cache.get(uid);

  // can't use await because we need to return a value now that isn't a promise
  getUserData(uid).then((user: UserPublicData) => {
    cache.set(uid, user);
    editor.render();
  });

  // return a blank user now and after the name loads, render will call and this
  // will be called again returning the value from cache.
  return { name: 'loading...' } as User;
}

export async function getUserData(uid: string) {
  const user = await getUser(uid).then();
  return user;
}

const colorCache = new Map<string, string>();
let count = 1;
const uidStore = plugins.stores.uid;

export function getAuthorColor(uid: string) {
  if (uidStore.get() === uid) {
    return 'author-0';
  }
  let color = colorCache.get(uid);
  if (!color) {
    color = `author-${count++}`;
    colorCache.set(uid, color);
  }
  return color;
}
