import { localeStore } from '@dabble/data/intl';
import { projectMetaStore, projectStore } from '@dabble/data/project-data';
import { Doc } from '@dabble/data/types';
import { userStore } from '@dabble/data/user-data';
import { getTitle } from '@dabble/data/util';
import { Image, ImageAttributes } from '@dabble/plugins/content/images';
import { Line, TextDocument } from 'typewriter-editor';
import { ExportFile, ParagraphOptions } from '../types';
import { editor, getDocName, getImageInfo } from '../utils';

export enum StyleNames {
  Normal = 'Normal',
  AuthorInfo = 'Author Info',
  AuthorAddress = 'Author Address',
  PageHeader = 'Page Header',
  NovelTitle = 'Novel Title',
  NovelSubtitle = 'Novel Subtitle',
  Heading1 = 'Heading 1',
  Heading2 = 'Heading 2',
  Heading3 = 'Heading 3',
  Heading4 = 'Heading 4',
  Heading5 = 'Heading 5',
  Heading6 = 'Heading 6',
  Scene = 'Scene',
  SceneFirstParagraph = 'Scene First Paragraph',
  Note = 'Note',
  NoteFirstParagraph = 'Note First Paragraph',
  SceneBreak = 'Scene Break',
  NovelQuote = 'Novel Quote',
  NovelQuoteAlternate = 'Novel Quote Alternate',
}

interface TypeExporter {
  (file: ExportFile, doc: Doc): void;
}

interface TypeExporters {
  [type: string]: TypeExporter;
}

const novelTypes: TypeExporters = {
  novel_book,
  novel_part,
  novel_chapter,
  novel_scene,
  novel_image,
  novel_plot,
  novel_book_scenes,
  novel_plot_line,
  novel_plot_point,
  novel_folder,
  novel_page,
  novel_cast,
  novel_character,
};

const handleOwnChildren = new Set(['novel_chapter']);

export function exportDoc(file: ExportFile, doc: Doc) {
  file.setPageSize(8.5 * 72, 11 * 72);

  processDoc(file, doc);
}

function processDoc(file: ExportFile, doc: Doc) {
  const processor = novelTypes[doc.type];
  if (processor) processor(file, doc);
  const children = projectStore.getChildren(doc.id);
  if (children && !handleOwnChildren.has(doc.type)) {
    const counts = file.data.counts;
    for (const child of children) {
      if (counts.export_chapters <= 0 || counts.export_pages <= 0) break;
      processDoc(file, child);
    }
  }
}

function novel_book(file: ExportFile, book: Doc) {
  const wordCount = Math.max(500, Math.round((file.data.counts.words || 10000) / 500) * 500);
  const numberWords = new Intl.NumberFormat(localeStore.get()).format(wordCount);
  const author = getTitle(book, 'author');
  const lastName = book.author?.split(' ').pop() || '[Author Last Name]';
  const phone = projectMetaStore.get()?.authorInfo?.phone || 'Phone Number';
  const address = projectMetaStore.get()?.authorInfo?.address || 'Address';
  const user = userStore.get();

  // Cover Art
  if (file.data.images && file.data.style !== 'manuscript' && book.image && book.image.url) {
    const image = { ...book.image } as ImageAttributes;
    image.image = book.image.url;
    file.addParagraph({ image: { style: 'full-page', ...getImageInfo(image) } }, '');
  }

  if (file.data.style === 'manuscript') {
    // Author Info
    file.addParagraph(
      { style: StyleNames.AuthorInfo },
      `${author || 'Author’s Legal Name'}\tAbout ${numberWords} words`
    );

    // Author Address
    file.addParagraph({ style: StyleNames.AuthorInfo }, user?.email || 'Email');
    const addressParagraphs = address.split('\n');
    addressParagraphs.forEach(line => {
      file.addParagraph({ style: StyleNames.AuthorAddress }, line);
    });
    file.addParagraph({ style: StyleNames.AuthorInfo }, phone);
  } else {
    file.addParagraph({}, '');
  }

  // Book Title & Author
  file.addParagraph({ style: StyleNames.NovelTitle }, getTitle(book));
  if (book.subtitle) file.addParagraph({ style: StyleNames.NovelTitle }, book.subtitle);
  file.addParagraph({ style: StyleNames.NovelSubtitle }, `by ${author}`);
  const section = file.addSection();
  section.addHeader().addParagraph({ style: StyleNames.PageHeader }, `${lastName} / ${file.data.title} / [[CURRENT]]`);
  file.pageNumbersStart(1);
}

function novel_part(file: ExportFile, doc: Doc) {
  file.addParagraph({}, '');
  if (file.data.images && file.data.style !== 'manuscript' && doc.image) {
    file.addParagraph({ image: { style: 'center', ...getImageInfo(doc.image as ImageAttributes) } }, '');
  }

  file.addParagraph({ style: StyleNames.Heading1 }, getDocName(file.data.counts, doc));
  if (doc.title) file.addParagraph({ style: StyleNames.Heading2 }, doc.title);
  file.addPageBreak();
}

function novel_image(file: ExportFile, doc: Doc) {
  if (!file.data.images || !doc.image) return;
  const image = doc.image as ImageAttributes;
  if (!image.image && (image as Image).url) image.image = (image as Image).url;
  file.addParagraph({ image: { style: 'full-page', ...getImageInfo(image) } }, '');
}

function novel_chapter(file: ExportFile, doc: Doc) {
  if (typeof file.data.counts.export_chapters === 'number') {
    if (file.data.counts.export_chapters <= 0) return;
    file.data.counts.export_chapters--;
  } else if (typeof file.data.counts.export_pages === 'number') {
    if (file.data.counts.export_pages <= 0) return;
  }
  file.addParagraph({}, ''); // add one empty line to allow the heading to use its spacingAbove
  if (file.data.images && file.data.style !== 'manuscript' && doc.image) {
    file.addParagraph({ image: { style: 'center', ...getImageInfo(doc.image as ImageAttributes) } }, '');
  }

  if (doc.unnumbered) {
    file.addParagraph({ style: StyleNames.Heading1 }, doc.title);
  } else {
    file.addParagraph({ style: StyleNames.Heading1 }, getDocName(file.data.counts, doc));
    if (doc.title) file.addParagraph({ style: StyleNames.Heading2 }, doc.title);
  }

  const separator = projectMetaStore.get()?.authorInfo?.sceneSeparator || '#';
  const children = projectStore.getChildren(doc.id);
  for (let i = 0; i < children.length; i++) {
    const doc = children[i];
    if (i) file.addParagraph({ style: StyleNames.SceneBreak }, separator);
    novel_scene(file, doc);
    file.data.counts.export_pages -= projectStore.get().counts[doc.id].pageCount;
    if (file.data.counts.export_pages <= 0) break;
  }

  file.addPageBreak();
}

function novel_scene(file: ExportFile, doc: Doc) {
  if (file.data.descriptions) {
    return novel_plot_point(file, doc);
  }

  parseTextDocument(file, doc.body, StyleNames.Scene, StyleNames.SceneFirstParagraph);
}

function novel_plot(file: ExportFile, doc: Doc) {
  file.addParagraph({ style: StyleNames.Heading1 }, getTitle(doc));
}

function novel_book_scenes(file: ExportFile, doc: Doc) {
  file.addParagraph({ style: StyleNames.Heading2 }, getTitle(doc));
}

function novel_plot_line(file: ExportFile, doc: Doc) {
  file.addParagraph({ style: StyleNames.Heading2 }, getTitle(doc));
}

function novel_plot_point(file: ExportFile, doc: Doc) {
  file.addParagraph({ style: StyleNames.Heading3 }, getTitle(doc));
  parseTextDocument(file, doc.description);
}

function novel_folder(file: ExportFile, doc: Doc) {
  file.addParagraph({ style: StyleNames.Heading1 }, getTitle(doc));
  file.addPageBreak();
}

function novel_page(file: ExportFile, doc: Doc) {
  file.addParagraph({ style: StyleNames.Heading1 }, getTitle(doc));
  parseTextDocument(file, doc.body);
  file.addPageBreak();
}

function novel_cast(file: ExportFile, doc: Doc) {
  novel_folder(file, doc);
}

function novel_character(file: ExportFile, doc: Doc) {
  if (file.data.images && doc.bannerUrl) {
    const image = { image: doc.bannerUrl, url: doc.bannerUrl, dataUrl: '', width: 0, height: 272 };
    file.addParagraph({ image: { ...getImageInfo(image) } }, '');
  }
  if (file.data.images && doc.image) {
    file.addParagraph({ image: { style: 'center', ...getImageInfo(doc.image as ImageAttributes) } }, '');
  }

  file.addParagraph({ style: StyleNames.Heading1 }, getTitle(doc));
  if (doc.subtitle) file.addParagraph({ style: StyleNames.Heading2 }, getTitle(doc, 'subtitle'));

  parseTextDocument(file, doc.body);
}

function parseTextDocument(
  file: ExportFile,
  doc: TextDocument,
  defaultStyle = StyleNames.Note,
  firstLineStyle: string = StyleNames.NoteFirstParagraph
) {
  if (!doc) return;
  let handledFirstLine = false;
  // Trim whitespace from beginning and end
  const lines = trimLines(doc);
  lines.forEach((line, i) => {
    const lines = editor.typeset.lines;
    const lineType = lines.findByAttributes(line.attributes, true);
    const options: ParagraphOptions = {};
    if (lineType === lines.get('header')) {
      options.style = 'Heading ' + line.attributes.header;
    } else if (lineType.name === 'list') {
      const kind: 'ordered' | 'bullet' = line.attributes.list;
      const { type, indent, start, checked } = line.attributes;
      options.list = { kind, type, indent, start, checked };
    } else if (lineType === lines.get('blockquote')) {
      options.style = line.attributes.blockquote === 'alt' ? StyleNames.NovelQuoteAlternate : StyleNames.NovelQuote;
    } else if (lineType === lines.get('image')) {
      if (!file.data.images) return;
      options.image = getImageInfo(line.attributes as ImageAttributes);
    } else if (lineType === lines.get('dlh')) {
      options.style === 'Definition List Heading';
    } else if (lineType === lines.get('dl')) {
      options.style === 'Definition List';
    } else if (lineType === lines.get('hr')) {
      options.hr = true;
    } else if (!handledFirstLine && firstLineStyle) {
      options.style = firstLineStyle;
      handledFirstLine = true;
    } else {
      options.style = defaultStyle;
    }

    file.addParagraph(options, line.content);
  });
}

function trimLines(doc: TextDocument) {
  const lines = doc.lines.slice();
  while (isWhitespace(lines.at(0))) lines.shift();
  while (isWhitespace(lines.at(-1))) lines.pop();
  return lines;
}

function isWhitespace(line: Line | undefined) {
  if (!line || Object.keys(line.attributes).length > 0) return false;
  if (line.length === 1) return true;
  return line.content.ops.every(op => (typeof op.insert === 'string' ? op.insert.trim() === '' : op.insert?.br));
}
