import { t } from '@dabble/data/intl';
import { projectStore } from '@dabble/data/project-data';
import { settingsStore } from '@dabble/data/settings';
import { Docs } from '@dabble/data/stores/project/docs';
import { Doc } from '@dabble/data/types';
import { truncateAtWord } from '@dabble/data/word-count';
import {
  mdiBook,
  mdiBookOpenBlankVariant,
  mdiBookOpenPageVariant,
  mdiFileImageOutline,
  mdiFileOutline,
  mdiImageAlbum,
} from '@mdi/js';
import i18next from 'i18next';
import { removeCoverArt, setCoverArt } from '../cover-art-menu-item';
import splitShortcut from '../editor-modules/split-shortcut';
import { joinSceneMenuItems } from '../join-scenes';
import { removeTitleImage, setTitleImage } from '../title-image-menu-item';

const placeholdersCache = new WeakMap<Docs, Map<Doc, string>>();
t.subscribe(() => placeholdersCache.delete(projectStore.get().docs));

function getCachedPlaceholder(doc: Doc) {
  const { childrenLookup, docs } = projectStore.get();
  let cache = placeholdersCache.get(docs);
  if (!cache) {
    // create cache to avoid extra processing with many lookups, esp. for projects with many chapters
    cache = placeholdersCache.set(docs, new Map()).get(docs);

    Object.values(docs).forEach(doc => {
      if (doc.type !== 'novel_book') return;
      let partNumber = 0;
      let chapterNumber = 0;
      childrenLookup[doc.id].forEach(function count(child) {
        if (child.type === 'novel_chapter' && !child.unnumbered) {
          cache.set(child, i18next.t('novel_chapter_number', { number: ++chapterNumber }));
        } else if (child.type === 'novel_part') {
          cache.set(child, i18next.t('novel_part_number', { number: ++partNumber }));
          childrenLookup[child.id].forEach(count);
        }
      });
    });
  }

  if (!cache.has(doc)) cache.set(doc, i18next.t(doc.type));
  return cache.get(doc);
}

settingsStore.configure('novel_book', {
  hasChildren: true,
  openByDefault: true,
  icon: doc => (doc.image ? mdiImageAlbum : mdiBook),
  menuItems: { setCoverArt, removeCoverArt },
  placeholders: {
    title: 'novel_book_untitled',
    subtitle: 'novel_book_unsubtitled',
    author: 'author_unknown',
  },
  hoverFolderToggle: true,
  validChildTypes: {
    novel_chapter: true,
    novel_part: true,
    novel_image: true,
  },
  structure: [
    {
      type: 'novel_chapter',
      children: [
        {
          type: 'novel_scene',
        },
      ],
    },
  ],
});

settingsStore.configure('novel_part', {
  hasChildren: true,
  openByDefault: true,
  icon: mdiBookOpenPageVariant,
  menuItems: { setTitleImage, removeTitleImage },
  placeholders: {
    title: (part: Doc) => getCachedPlaceholder(part),
  },
  unstyledPlaceholders: {
    title: true,
  },
  hoverFolderToggle: true,
  validChildTypes: {
    novel_chapter: true,
    novel_image: true,
  },
  structure: [
    {
      type: 'novel_chapter',
      children: [
        {
          type: 'novel_scene',
        },
      ],
    },
  ],
  convertToTemplate: true,
});

settingsStore.configure('novel_chapter', {
  hasChildren: true,
  openByDefault: true,
  icon: mdiBookOpenBlankVariant,
  menuItems: { setTitleImage, removeTitleImage },
  placeholders: {
    title: (chapter: Doc) => getCachedPlaceholder(chapter),
  },
  unstyledPlaceholders: {
    title: true,
  },
  unselectable: false,
  hoverFolderToggle: true,
  defaultChildType: 'novel_scene',
  mentionsMenu: true,
  validChildTypes: {
    novel_scene: true,
    novel_image: true,
  },
  structure: [
    {
      type: 'novel_scene',
    },
  ],
  convertToTemplate: true,
});

settingsStore.configure('novel_scene', {
  icon: mdiFileOutline,
  menuItems: { joinScenes: joinSceneMenuItems },
  placeholders: {
    title: (doc: Doc) => {
      const content = (projectStore.textFor(doc.id, 'body') || '').replace(/\n+/g, ' ').trim();
      const firstLine = truncateAtWord(content, 50);
      if (firstLine) return firstLine;
      return isFirstScene(doc) ? i18next.t('novel_scene_body_first') : i18next.t('novel_scene_title_empty');
    },
    body: (doc: Doc) => (isFirstScene(doc) ? i18next.t('novel_scene_body_first') : i18next.t('novel_scene_body_empty')),
    description: 'novel_scene_description_empty',
  },
  editorOptions: {
    body: {
      typeset: {
        lines: ['paragraph', 'blockquote', 'image'],
        formats: ['highlight', 'comment', 'bold', 'italic', 'underline'],
        embeds: ['image', 'br', 'decoration'],
      },
    },
  },
  editorModules: {
    splitShortcut,
  },
  mentionsMenu: true,
  imageStyles: ['outset-left', 'full-page'],
  convertToTemplate: true,
});

settingsStore.configure('novel_image', {
  icon: mdiFileImageOutline,
  hideWordCount: false,
  placeholders: {
    title: 'novel_image',
  },
  unstyledPlaceholders: {
    title: true,
  },
});

function isFirstScene(scene: Doc) {
  const { parentsLookup, childrenLookup } = projectStore.get();
  const chapter = parentsLookup[scene.id];
  if (!chapter || chapter.type !== 'novel_chapter' || childrenLookup[chapter.id][0] !== scene) return false;
  const book = parentsLookup[chapter.id];
  if (!book || (book.type !== 'novel_book' && book.type !== 'novel_part') || childrenLookup[book.id][0] !== chapter)
    return false;
  return true;
}
